import { EventEmitter } from '@angular/core';
var MdDateComponent = /** @class */ (function () {
    function MdDateComponent() {
        this.float = true;
        this.disabled = false;
        this.input = new EventEmitter();
        this.changed = new EventEmitter();
        this.focus = new EventEmitter();
        this.blur = new EventEmitter();
        this._isFocused = false;
        this._type = 'text';
    }
    Object.defineProperty(MdDateComponent.prototype, "valueAsDate", {
        set: function (_val) {
            if (_val) {
                this._type = 'date';
                this._valueAsDate = new Date(_val);
            }
            else {
                this._type = 'text';
                this._valueAsDate = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(MdDateComponent.prototype, "min", {
        set: function (_val) {
            if (_val) {
                var minDate = new Date(_val);
                this.min = minDate.getFullYear() + '-' + this.addLeadingZeros(minDate.getMonth() + 1) + '-' + this.addLeadingZeros(minDate.getDate());
                ;
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(MdDateComponent.prototype, "max", {
        set: function (_val) {
            if (_val) {
                var maxDate = new Date(_val);
                this.max = maxDate.getFullYear() + '-' + this.addLeadingZeros(maxDate.getMonth() + 1) + '-' + this.addLeadingZeros(maxDate.getDate());
                ;
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    MdDateComponent.prototype.addLeadingZeros = function (_val) {
        var _valstr = _val.toString();
        if (_valstr.length < 2)
            return '0' + _valstr;
        else
            return _valstr;
    };
    MdDateComponent.prototype.onInput = function (event) {
        this.input.emit(event);
    };
    MdDateComponent.prototype.onChanged = function (event) {
        this.changed.emit(event);
    };
    MdDateComponent.prototype.onFocus = function (event) {
        this._type = 'date';
        this._isFocused = true;
        this.focus.emit(event);
    };
    MdDateComponent.prototype.onBlur = function (event) {
        //if(!this._valueAsDate) this._type = 'text';
        if (event.target.valueAsDate) {
            this._type = 'date';
            this._valueAsDate = new Date(event.target.valueAsDate);
        }
        else {
            this._type = 'text';
            this._valueAsDate = null;
        }
        this._isFocused = false;
        this.blur.emit(event);
    };
    return MdDateComponent;
}());
export { MdDateComponent };
