import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatMenuTrigger} from '@angular/material';

@Component({
  selector: 'app-customer-chip',
  templateUrl: './customer-chip.component.html',
  styleUrls: ['./customer-chip.component.scss']
})
export class CustomerChipComponent implements OnInit {
  @Input('size') size:string = 'sm';
  @Input('customer') customer:any;
  @Input('removable') removable:boolean = false;
  @Input('max-width') maxWidth:string = '';
  @Output('remove') removeEvent:EventEmitter<any> = new EventEmitter();
  trigger: MatMenuTrigger;

  constructor() { }

  ngOnInit() {
  }

  openMenu() {
    this.trigger.openMenu();
  }

  removeClick(customerId, $event){
    let me = this, event = {};
    $event.stopPropagation();
    this.removeEvent.emit(customerId);
  }
}
