import { Injectable } from '@angular/core';
import { CanActivate, Router,
         ActivatedRouteSnapshot,
         RouterStateSnapshot } from '@angular/router';
import { MyServerService } from './my-server.service';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';

@Injectable()
export class AuthGuardClientService implements CanActivate {
  constructor(private authService: MyServerService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {    
    let me = this;
    if (me.authService.isLoggedIn) return Observable.create(_s =>{ 
      _s.next(true);
      _s.complete();
    });
    else if(me.authService.isLoggedIn === undefined)  return me.authService.loggedIn.pipe(map((_user)=>{
      if(!_user) {
        me.authService.redirectUrl = state.url;
        me.router.navigate(['signin']);
      }
      return !!_user;
    }),take(1));
    // Store the attempted URL for redirecting
     me.authService.redirectUrl = state.url;
     //console.log('signin redirect')
    // // Navigate to the login page
     me.router.navigate(['signin']);
     return false;
  }
}
