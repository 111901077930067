import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges } from '@angular/core';
import { toCanvas } from 'qrcode';
//import { QRCode } from 'qrcode';
import { AfterViewInit } from '@angular/core/src/metadata/lifecycle_hooks';

declare var JsBarcode;

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss']
})
export class BarcodeComponent implements OnInit, OnChanges, AfterViewInit {
  // @ViewChild('barcodeSvg') barcodeSvg:ElementRef;
  @ViewChild('qrCanvas') qrCanvas:ElementRef;
  @Input('value') value: string;
  @Input('text') text: string;
  @Input('bar-width') width: any;
  @Input('bar-height') height: any;
  @Input('display-value') displayValue: boolean = true;
  @Input('font') font: string;
  @Input('text-align') textAlign: string;
  @Input('text-position') textPosition: string = 'center';
  @Input('text-margin') textMargin: any;
  @Input('font-size') fontSize: any;
  @Input('background-color') background: string;
  @Input('line-color') lineColor: string;
  @Input('margin') margin: any;
  @Input('margin-top') marginTop: any;
  @Input('margin-bottom') marginBottom: any;
  @Input('margin-left') marginLeft: any;
  @Input('margin-right') marginRight: any;
  @Input('text-margin-top') textMarginTop: any;


  constructor() { }

  ngOnInit() {
    this.render()
  }

  ngOnChanges(){
    this.render()
  }

  ngAfterViewInit(){
    //this.render()
  }

  render(){
    if(this.qrCanvas && this.qrCanvas.nativeElement){
      let me = this;
      let options:any = { scale:2 };
      if(me.width) options.width = parseFloat(me.width);
      if(me.height) options.height = parseFloat(me.height);
      if(me.displayValue !== undefined) options.displayValue = me.displayValue;
      if(me.font) options.font = me.font;
      if(me.textAlign) options.textAlign = me.textAlign;
      if(me.textPosition) options.textPosition = me.textPosition;
      if(me.textMargin) options.textMargin = parseFloat(me.textMargin);
      if(me.fontSize) options.fontSize = parseFloat(me.fontSize);
      if(me.background) options.background = me.background;
      if(me.lineColor) options.lineColor = me.lineColor;
      if(me.margin) options.margin = parseFloat(me.margin);
      if(me.marginTop) options.marginTop = parseFloat(me.marginTop);
      if(me.marginBottom) options.marginBottom = parseFloat(me.marginBottom);
      if(me.marginLeft) options.marginLeft = parseFloat(me.marginLeft);
      if(me.marginRight) options.marginRight = parseFloat(me.marginRight);
      //JsBarcode(this.barcodeSvg.nativeElement, this.value || "Loading", options);

      toCanvas(this.qrCanvas.nativeElement, this.value || "Loading",{errorCorrectionLevel: 'H', ...options});
      
    }
    
  }

}

