import { BarcodeScannerService } from './../services/barcode-scanner.service';
import { Directive, Input, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[barcode-scanner]'
})
export class BarcodeScannerDirective {
  @Input('barcode-scanner') barcodeScanner:string
  @HostListener('focus') onFocus() {
    let me = this;
    //console.log('focus')
    if(me.barcodeScanner && me.barcodeScanner.length > 0) {
      //console.log(me.barcodeScanner)
      me._barcodeEvent = me.BarcodeScannerService.OverRidingBarcodeEvent(me.barcodeScanner).subscribe(_barcode=>{
        //console.log(_barcode)
        //me.onInput({target:{value:_barcode.text}})
        me.el.nativeElement.value = _barcode.text
        
        me.el.nativeElement.dispatchEvent(new Event('input', { 'bubbles': true }))
      })
    }
  }
  @HostListener('blur') onBlur() {
    let me = this;
    //console.log('blur')
    if(me._barcodeEvent && me._barcodeEvent.unsubscribe) me._barcodeEvent.unsubscribe()
  }

  private _barcodeEvent:any;


  constructor(private el:ElementRef,private BarcodeScannerService:BarcodeScannerService) { }

}
