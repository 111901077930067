import {Component,Input,Output,EventEmitter} from '@angular/core';

@Component({
  selector: 'app-md-textarea',
  templateUrl: './md-textarea.component.html',
  styleUrls: ['./md-textarea.component.scss']
})
export class MdTextareaComponent {
  @Input('value') value:any;
  @Input('placeholder') placeholder:string;
  @Input('float') float:boolean = true;
  @Input() disabled: boolean = false;
  @Input('required') required:boolean;
  @Output('input') input:EventEmitter<any> = new EventEmitter();
  @Output('changed') changed:EventEmitter<any> = new EventEmitter();
  @Output('focus') focus:EventEmitter<any> = new EventEmitter();
  @Output('blur') blur:EventEmitter<any> = new EventEmitter();
  _isFocused = false;

  constructor(){

  }

  onInput(event){
      this.value = event.target.value;
      this.input.emit(event);
  }

  onChanged(event) {
      this.value = event.target.value;
      this.changed.next(event);
  }

  onFocus(event) {
      this._isFocused = true;
      this.focus.emit(event);
  }

  onBlur(event) {
      this.value = event.target.value;
      this._isFocused = false;
      this.blur.emit(event);
  }
}
