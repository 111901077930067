import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import {Http, Response,RequestOptions,BaseRequestOptions} from '@angular/http';
import {MyServerService} from '../services/my-server.service'

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class SigninComponent implements OnInit {

 emailVCode = "email";
  key:string = "";
  email:string = "";
  showNoEmailFoundMsg = false;
  showBadCodeMsg = false;
  sendingCodeInprogress = false;
  checkingCodeInProgress = false;

  constructor(public Http:Http,public MyServerService : MyServerService, public router:Router, public cd: ChangeDetectorRef) { }

  ngOnInit() {
    //console.log('signin init')
    let me = this;
    me.email = me.MyServerService.email || '';
  }

  emailChange(_val) {
    let me = this;
    me.email = _val
    me.cd.detectChanges();
  }

  keyChange(_val) {
    let me = this;
    me.key = _val
    me.cd.detectChanges();
  }

  sendLogInCode(){
    let me = this;
    me.sendingCodeInprogress = true;
    me.showNoEmailFoundMsg = false;
    me.MyServerService.sendLogInCode(me.email).then(_result =>{
      me.sendingCodeInprogress = false;
      if(_result == 'code sent') me.emailVCode = 'code';
      else if(_result == 'no email found') me.showNoEmailFoundMsg = true;
      me.cd.markForCheck();
    });

  }

  login(keepMeLoggedIn){
    let me = this;
    me.checkingCodeInProgress = true;
    me.showBadCodeMsg = false;;
    me.MyServerService.login(me.key, keepMeLoggedIn).then(_result =>{
      me.checkingCodeInProgress = false;;
      if(_result){
        let redirect = me.MyServerService.redirectUrl ? me.MyServerService.redirectUrl : 'Portal';
        me.router.navigate([redirect]);
      }
      else me.showBadCodeMsg = true;
      me.cd.markForCheck();
    });

  }

}
