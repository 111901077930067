import { UsersService } from './users.service';
import { Router } from '@angular/router';
import { MyServerService } from './my-server.service';
import { Observable, Subject, fromEvent } from 'rxjs';
import { share, filter, first, map, bufferTime } from 'rxjs/operators';
import { Injectable, Renderer, Inject, EventEmitter } from '@angular/core';
//import * as rx from 'rxjs/Rx';

declare const localStorage;
declare const window;
declare const location;

@Injectable()
export class BarcodeScannerService {
  keyUpEvent:Observable<any> = fromEvent(window.document,'keydown').pipe(share())
  public barcodeEvent:Observable<any>
  OverRiddingLissenersType:string
  OverRiddingLisseners:Subject<any>
  constructor(private MyServerService:MyServerService,private Router:Router, private UsersService:UsersService) {
    let me = this;
    me.barcodeEvent =  me.keyUpEvent.pipe(filter((_ev:any)=>_ev.key!="Shift"),bufferTime(1500,0,25),filter((_evs:any[])=>{
      //console.log(_evs)
      //console.log(`_evs.length == 6 ${_evs.length == 6} && _evs[5].key == 'Enter' ${_evs[5].key == 'Enter'}`)
      return (_evs.length == 25 && _evs[24].key == 'Enter') ;
    }),map(_evs=>{
      return _evs.map(_ev => _ev.key).filter(_k => _k != 'Enter').join('')
    }),filter(_barcode=>_barcode.length == 24),share())


    me.barcodeEvent.subscribe(_barcode=>{

      me.UsersService.getUser().pipe(first()).toPromise().then(_user =>{
        //if(_user.email.indexOf('@biadiagnostics.com') > -1 || _user.email.indexOf('@devhat.io') > -1 && _barcode != _user._id){
          me.UsersService.getUserById(_barcode).then(_newUser =>{
            window.localStorage.setItem('email', _newUser.email);
            window.localStorage.setItem('keepMeLoggedInKey', _newUser.keepMeLoggedInKey[0]);
            window.document.cookie = "express.sid=null; path=/";
            window.location.reload(true);
          })
        //}
      })
      //console.log(_barcode)
      // me.MyServerService.publicFunction('getBarcode',{barcode:_barcode}).toPromise().then(_data=>{
      //   //console.log(`_data.barcode.type == me.OverRiddingLissenersType ${_data.barcode.type == me.OverRiddingLissenersType}`)
      //   if(_data.barcode && _data.barcode.type == me.OverRiddingLissenersType && me.OverRiddingLisseners) me.OverRiddingLisseners.next(_data.barcode)
      //   else if(_data.barcode && _data.barcode.type == 'user'){
      //     //todo switch user
      //   }
      //   else if(_data.barcode){
      //     //todo goto fx
      //   }
      // })
    })
  }

  OverRidingBarcodeEvent(type:string):Observable<any>{
    let me = this;
    me.OverRiddingLissenersType = type;
    return Observable.create((_s)=>{
      me.OverRiddingLisseners = _s;
      return ()=>{
        if(me.OverRiddingLissenersType == type) me.OverRiddingLissenersType = undefined;
      }
    })
  }

}
