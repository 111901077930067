import { Injectable, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import {MyServerService} from './my-server.service'
//import {Observable, Subject, Subscription} from 'rxjs/Rx';
declare const NDEFReader
@Injectable()
export class BarcodeCartService {
  _barcodes: {barcode:string,biaId:string, height?:Number, width?:number}[] = [];
  barcodeEmiter = new EventEmitter()
  count = new EventEmitter()
  constructor(public snackBar: MatSnackBar, public MyServerService:MyServerService) { }


  async createBarcode(_barcode){
    return await this.MyServerService.publicFunction('createBarcode',{text:_barcode}).toPromise()
  }

  async checkIfBarcodeExists(_barcode){
    return await this.MyServerService.publicFunction('getBarcode',{barcode:_barcode}).toPromise()
  }

  add(_barcode:{barcode:string,biaId:string, height?:Number, width?:number}){
    if(this._barcodes.findIndex(__barcode => __barcode.barcode == _barcode.barcode) < 0){
      this._barcodes.push(_barcode);
      this.barcodeEmiter.emit(this._barcodes);
      this.count.emit(this._barcodes.length);
    }
  }

  remove(_barcode){
    let i = this._barcodes.indexOf(_barcode);
    if(i > -1){
      this._barcodes.splice(i,1);
      this.barcodeEmiter.emit(this._barcodes);
      this.count.emit(this._barcodes.length);
    }
  }

  barcodes(){
    let me = this;
    setTimeout(function() {
      me.barcodeEmiter.emit(me._barcodes)
    }, 0);
    return me.barcodeEmiter
  }

  async writeToNFC(_barcode){
    try {

      const ndef = new NDEFReader();
      await ndef.write(JSON.stringify(_barcode));
      this.snackBar.open('Barcode written to NFC')

    } catch (error) {
      console.log(error)
      this.snackBar.open('Error writing Barcode to NFC')
    }
  }

}
