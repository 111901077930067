/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./qr-scanner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./qr-scanner.component";
import * as i11 from "../../services/my-server.service";
import * as i12 from "@angular/router";
import * as i13 from "@angular/material/dialog";
import * as i14 from "../../services/dialog-data.service";
var styles_QrScannerComponent = [i0.styles];
var RenderType_QrScannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QrScannerComponent, data: {} });
export { RenderType_QrScannerComponent as RenderType_QrScannerComponent };
function View_QrScannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["aria-label", "nfc"], ["class", "mat-icon"], ["role", "img"], ["style", "float: right;margin-top: -45px;font-size: 48px;"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["nfc"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
export function View_QrScannerComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { video: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["style", "margin-top: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", "\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QrScannerComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["style", "padding-bottom: 16px;font-weight: 600;"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" Scanned: ", " ", "\n"])), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["style", "position:relative;border-radius: 8px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["video", 1]], null, 0, "video", [["style", "max-width: 100%; "]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "qrSqare"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "subSqare"]], [[2, "has-code", null]], null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "subSqare-top-left"]], [[2, "has-code", null]], null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "subSqare-top-right"]], [[2, "has-code", null]], null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["class", "subSqare-bottom-right"]], [[2, "has-code", null]], null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [["class", "subSqare-bottom-left"]], [[2, "has-code", null]], null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 4, "button", [["mat-fab", ""], ["style", "float: left;margin-top: -25px;margin-left: -25px;"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.togelCamera() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(17, 0, null, 0, 2, "mat-icon", [["aria-label", "cameraswitch"], ["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(18, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["cameraswitch"])), (_l()(), i1.ɵeld(20, 0, null, null, 4, "button", [["mat-fab", ""], ["style", "float: right;margin-top: -25px;margin-right: -25px;"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(21, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(22, 0, null, 0, 2, "mat-icon", [["aria-label", "cameraswitch"], ["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(23, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.canReadNFC; _ck(_v, 4, 0, currVal_1); _ck(_v, 18, 0); _ck(_v, 23, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_2 = (_co.type || ""); var currVal_3 = (_co.result || "none"); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = _co.hasCode; _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.hasCode; _ck(_v, 11, 0, currVal_5); var currVal_6 = _co.hasCode; _ck(_v, 12, 0, currVal_6); var currVal_7 = _co.hasCode; _ck(_v, 13, 0, currVal_7); var currVal_8 = _co.hasCode; _ck(_v, 14, 0, currVal_8); var currVal_9 = (i1.ɵnov(_v, 16).disabled || null); var currVal_10 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_9, currVal_10); var currVal_11 = i1.ɵnov(_v, 18).inline; _ck(_v, 17, 0, currVal_11); var currVal_12 = (i1.ɵnov(_v, 21).disabled || null); var currVal_13 = (i1.ɵnov(_v, 21)._animationMode === "NoopAnimations"); _ck(_v, 20, 0, currVal_12, currVal_13); var currVal_14 = i1.ɵnov(_v, 23).inline; _ck(_v, 22, 0, currVal_14); }); }
export function View_QrScannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-qr-scanner", [], null, null, null, View_QrScannerComponent_0, RenderType_QrScannerComponent)), i1.ɵdid(1, 4440064, null, 0, i10.QrScannerComponent, [i1.NgZone, i1.ChangeDetectorRef, i11.MyServerService, i12.Router, i13.MatDialogRef, i14.DialogDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QrScannerComponentNgFactory = i1.ɵccf("app-qr-scanner", i10.QrScannerComponent, View_QrScannerComponent_Host_0, {}, {}, []);
export { QrScannerComponentNgFactory as QrScannerComponentNgFactory };
