import {Component,Input,Output,EventEmitter} from '@angular/core';

@Component({
  selector: 'app-md-number',
  templateUrl: './md-number.component.html',
  styleUrls: ['./md-number.component.scss']
})
export class MdNumberComponent {
  @Input('type') type:string = 'number';
  @Input('placeholder') placeholder:string;
  @Input('float') float:boolean = true;
  @Input('min') min:number;
  @Input('max') max:number;
  @Input('value')
    get value():number {
        if(this.type == 'percent') {
            return this._value / 100;
        }
        else {
            return this._value;
        }
    };
    set value(_val:number){
        if(_val || _val == 0) {
            if(this.type == 'percent') {
                this._value = _val * 100;
            }
            else {
                this._value = _val;
            }
        }
        else {
            this._value = null;
        }
    };

  @Input() disabled: boolean = false;
  @Input('required') required:boolean;
  @Output('input') input:EventEmitter<any> = new EventEmitter();
  @Output('changed') changed:EventEmitter<any> = new EventEmitter();
  @Output('focus') focus:EventEmitter<any> = new EventEmitter();
  @Output('blur') blur:EventEmitter<any> = new EventEmitter();
  _isFocused = false;
  _value;

  constructor(){

  }

  onInput(event){
    //   this._value = event.target.value;
      if(this.type == 'percent') event.target.pctValue = (parseFloat(event.target.value) / 100) || null;
      this.input.emit(event);
  }

  onChanged(event) {
    //   this._value = event.target.value;
      if(this.type == 'percent') event.target.pctValue = (parseFloat(event.target.value) / 100) || null;
      this.changed.emit(event);
  }

  onFocus(event) {
      this._isFocused = true;
      this.focus.emit(event);
  }

  onBlur(event) {
    this._isFocused = false;
    // this._value = event.target.value;
    if(this.type == 'percent') event.target.pctValue = (parseFloat(event.target.value) / 100) || null;
    this.blur.emit(event);
  }
}

