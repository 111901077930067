import { Injectable, Inject, NgZone } from '@angular/core';
import {MyServerService} from './my-server.service';
import {Query} from './momgo';
//import {serverRx} from 'rx-server/clientScripts/rxServer';
import {Observable} from 'rxjs';
import {map, filter, first} from 'rxjs/operators';

@Injectable()
export class UsersService extends Query {
    userId
    _userKeysPulled = false;
    userSettings:any = {};
  constructor( @Inject(MyServerService) public myServer:MyServerService){
        super(myServer,"currentUser","saveUser");
        let me = this;
        me.myServer.publicFunction('bootCurrentUser').subscribe(_data =>{
            me.myServer.logout()
            
        })

    }

    setUserSettings(key,value){
        if(!this.userSettings) this.userSettings = {}
        this.userSettings[key] = value;
        localStorage.setItem('settings-' + this.userId, JSON.stringify(this.userSettings) );
    }

    async getUserSettings(key){
        if(!this._userKeysPulled){
            try{
                this._userKeysPulled = true;
                if(!this.userId) await this.getUser().pipe(first()).toPromise();
                let savedSettings = localStorage.getItem('settings-' + this.userId);
                if(savedSettings) this.userSettings = JSON.parse(savedSettings)
            }catch(error){
                
            }
        }
        return this.userSettings[key]
    }

    getUser():Observable<Iuser>{
        let me = this;
        return me.get().pipe(filter(_users =>{
            return _users[0] != null;
        }),map( (_users:Iuser[]) => {
            //if(!me.BrowserService.isModern) me.NgZone.run(()=>{})
            me.userId = _users[0]._id;
            let savedSettings
            try {
                this._userKeysPulled = true;
                savedSettings = localStorage.getItem('settings-' + _users[0]._id);
                if(savedSettings) me.userSettings = JSON.parse(savedSettings)
            } catch (error) {
                me.userSettings = {};
            }
            
            return _users[0]
        }))
    }

    findUser(find:string){
        let me = this;
        return me.myServer.publicFunction('findUsers',{find}).pipe(map((_data):Iuser[] => _data.docs)).toPromise()
    }

    findBiaUser(find:string){
        let me = this;
        return me.myServer.publicFunction('findBiaUsers',{find}).pipe(map(_data => _data.docs)).toPromise()
    }

    newEmailUser(email:string){
        let me = this;
        return me.myServer.publicFunction('newEmailUser',{email}).pipe(map(_data => _data._id)).toPromise()
    }

    getUserById(_id):Promise<Iuser>{
      let me = this;
      return me.myServer.publicFunction('getUser',{_id}).toPromise().then(_data =>{
        return _data.user;
      })
    }

    ping(){
        let me = this;
        return me.myServer.publicFunction('ping',{
            client:new Date()
        }).toPromise().then(data =>{
            return {
                ...data,
                latency:new Date().getTime() - new Date(data.client).getTime(),
            }
        })
    }


}

export interface Iuser{
    _id:string,
    firstName?:string,
    lastName?:string,
    email:string,
    customers?:any[],
    visit?:number,
    active:boolean,
    phoneNumber?:string,
    emailResultsforFacilities?:{[_id:string]:boolean},
    permissions?:{
        admin?:boolean,
        labTech?:boolean,
        customerAdmin?:{[_id:string]:boolean}
    },
    displayName?:string,
    keepMeLoggedInKey?:string[]
}
