import { AuthGuardPrintService } from './services/auth-guard-print.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './signin/signin.component'
import { AuthGuardClientService } from './services/auth-guard-client.service';
import { ReportsComponent } from './reports/reports.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/Portal',
    pathMatch: 'full'
  },
  {
    path: 'signin',
    component: SigninComponent
  },
  {
    path: 'report/:id/:code/:name',
    component: ReportsComponent
  },
  {
    path: 'Portal',
    loadChildren:'app/client-portal/client-portal.module#ClientPortalModule',
    canActivate:[AuthGuardClientService]
  }
  ,
  {
    path: 'Print',
    loadChildren:'app/print/print.module#PrintModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
