import { BarcodeScannerService } from './services/barcode-scanner.service';
import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MyServerService } from './services/my-server.service'
import { CheckForUpdateService } from 'app/services/sw.service';
import { NavigationEnd, Router } from '@angular/router';

declare function cwr(operation: string, payload: any): void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  settingsSideNav = false;
  siteSideNav = false;
  signedIn:boolean = false;

  constructor(private MyServerService: MyServerService, private BarcodeScannerService:BarcodeScannerService, private cd:ChangeDetectorRef, private sw:CheckForUpdateService, private router: Router) {
    let me = this;
    me.MyServerService.loggedIn.subscribe(_signedIn => {
      me.signedIn = !!_signedIn
      me.cd.markForCheck()
    })
  }

  toggleSettingsSideNav(ev) {
    if(ev) this.settingsSideNav = true;
    else if(ev == false) this.settingsSideNav = false;
    else this.settingsSideNav = !this.settingsSideNav;
  }
  toggleSiteSideNav(ev) {
    if(ev) this.siteSideNav = true;
    else if(ev == false) this.siteSideNav = false;
    else this.siteSideNav = !this.siteSideNav;
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        //console.log('this.router.url');
        cwr('recordPageView', this.router.url);
      }
    });
  }


}
