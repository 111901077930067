import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportsComponent implements OnInit {

  code  = '';
  id='';
  name='';

  url;

  errorMsg

  constructor(public ActivatedRoute:ActivatedRoute, public http:HttpClient, public sanitizer: DomSanitizer, public cd:ChangeDetectorRef) {

   }

  async ngOnInit() {
    this.code = this.ActivatedRoute.snapshot.params['code'];
    this.id = this.ActivatedRoute.snapshot.params['id'];
    this.name = this.ActivatedRoute.snapshot.params['name'];

    let reportInfo:any = await this.http.post(environment.sever + "/reportsInfo",{
      id:this.id,
      code:this.code,
      name:this.name
    }).toPromise()

    if(reportInfo.surl) this.url = this.sanitizer.bypassSecurityTrustResourceUrl(reportInfo.surl);

    if(reportInfo.error) this.errorMsg = "The report could not be found please contatct Bia Diagnostics.";

    if(reportInfo.latestPrint) this.errorMsg = "You do not have the latest version of this report. Find the latest version below."


    console.log(reportInfo)

    this.cd.markForCheck()

  }

}
