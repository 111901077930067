import { Component, OnInit, ViewChild, ElementRef, NgZone, ChangeDetectionStrategy, ChangeDetectorRef, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import {  Router } from '@angular/router';
import { MyServerService } from '../../services/my-server.service';
import QrScanner from 'qr-scanner';
import { MatDialogRef } from '@angular/material';
import { DialogDataService } from '../../services/dialog-data.service'
declare const NDEFReader
@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QrScannerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('video') video: ElementRef;
  currentCamera = 0;
  cameras = [];
  qrScanner:QrScanner
  result = '';
  type = ''
  title = "Global Scanner"
  hasCode = false;
  canReadNFC = false;
  controller = new AbortController();
  signal = this.controller.signal;

  constructor(private NgZone:NgZone, private cd:ChangeDetectorRef, private MyServerService:MyServerService, private router:Router, public dialogRef: MatDialogRef<QrScannerComponent>,
    private DialogDataService:DialogDataService) { }

  togelCamera(){
    this.currentCamera++;
    if(this.currentCamera >= this.cameras.length){
      this.currentCamera = 0;
    }
    if(this.cameras[this.currentCamera]) this.qrScanner.setCamera(this.cameras[this.currentCamera].id)
  }

  ngOnInit() {
    if(this.DialogDataService.data.title){
      this.title = this.DialogDataService.data.title
    }
    if (this.qrScanner && this.qrScanner.start){
      this.qrScanner.start().then(()=>{
        QrScanner.listCameras(true).then((cameras)=>{
          this.cameras = cameras;
          console.log(cameras)
        })
      });
    }

    try {
      const ndef = new NDEFReader();
      ndef.scan({signal:this.signal}).then(() => {
        //console.log("Scan started successfully.");
        this.canReadNFC = true;
        this.cd.markForCheck();
        ndef.onreadingerror = () => {
          console.log("Cannot read data from the NFC tag. Try another one?");
        };
        ndef.onreading = event => {
          console.log("NDEF message read.");
          const message = event.message;
          if(!message || !message.records) return;
          const record = message.records.find((record)=>record.recordType =="text");
          if(record){
            const textDecoder = new TextDecoder(record.encoding);
            this.newCode(JSON.parse(textDecoder.decode(record.data)).barcode)
          }
        };
      }).catch(error => {
        console.log(`Error! Scan failed to start: ${error}.`);
      });
    } catch (error) {
      
    }
  }

  ngOnDestroy(){
    if (this.qrScanner && this.qrScanner.stop){
      this.qrScanner.stop()
    }
    if(this.controller && this.signal){
      try {
        this.controller.abort()
      } catch (error) {
        
      }
      
    }
  }

  ngAfterViewInit(){
    const me = this
    this.qrScanner = new QrScanner(this.video.nativeElement, result => {
      me.NgZone.run(()=>{
        //console.log('decoded qr code:', result)
        me.hasCode = true;
        me.cd.markForCheck();
        me.newCode(result.data)
      })
    },{
      // highlightScanRegion: true,
      // highlightCodeOutline:true
    });
    
    this.qrScanner.start().then(()=>{
      QrScanner.listCameras(true).then((cameras)=>{
        this.cameras = cameras;
        console.log(cameras)
      })
    });
  }

  newCode(val){
    if(val.startsWith("https")){
      if(val.startsWith("https://portal.biadiagnostics.com/")) window.open(val,"_self")
      else window.open(val,"_blank")
    }
    else{
      this.result = val;
      this.type = "unknown type:"
      this.MyServerService.publicFunction('getBarcode',{barcode:val}).toPromise().then((_data)=>{
        if(!_data || !_data.barcode){
          this.result = "QR-Code not found"
          this.type = ""
          this.cd.markForCheck()
          return false
        }
        if(_data.barcode){
          if(_data.barcode.type == "sampleSubmission"){
            //sampleSubmission
            this.router.navigate([_data.barcode.url.replace("/#/","/Portal/")]) //['/Portal/sampleSubmissionform',_data.barcode._id]
            
            this.type = "Sample Submission Form:"
            this.result = _data.barcode.text
            this.cd.markForCheck()


            if(!this.DialogDataService.data.forField) {
              this.router.navigate([_data.barcode.url.replace("/#/","/Portal/")])
            }
            this.dialogRef.close(_data.barcode.text) 

            
            return true
          }
          if(_data.barcode.type == "equipment"){

            this.type = "Equipment:"
            this.result = _data.barcode.text

            if(!this.DialogDataService.data.forField) {
              this.router.navigate(['/Portal/Bia/equipment'],{
                queryParams:{
                  search:_data.barcode.text
                }
              }) //"equipment/5ef3aa635bcc24410de7c8fe"
            }

            this.dialogRef.close(_data.barcode.url.replace('equipment/','')) 
           
            return true
          }

          this.result = _data.barcode.text
          this.type = _data.barcode.type + ":"
          this.cd.markForCheck()
          console.dir(_data.barcode)
          this.dialogRef.close(_data.barcode.text) 
          return true
        }


        this.result = "QR-Code not found"
        this.type = ""
        this.cd.markForCheck()
        return false
        
      })
    }
    this.cd.markForCheck()
    
  }

  close(){
    this.dialogRef.close()
  }

}
