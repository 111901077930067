import { EventEmitter } from '@angular/core';
var MdNumberComponent = /** @class */ (function () {
    function MdNumberComponent() {
        this.type = 'number';
        this.float = true;
        this.disabled = false;
        this.input = new EventEmitter();
        this.changed = new EventEmitter();
        this.focus = new EventEmitter();
        this.blur = new EventEmitter();
        this._isFocused = false;
    }
    Object.defineProperty(MdNumberComponent.prototype, "value", {
        get: function () {
            if (this.type == 'percent') {
                return this._value / 100;
            }
            else {
                return this._value;
            }
        },
        set: function (_val) {
            if (_val || _val == 0) {
                if (this.type == 'percent') {
                    this._value = _val * 100;
                }
                else {
                    this._value = _val;
                }
            }
            else {
                this._value = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    ;
    MdNumberComponent.prototype.onInput = function (event) {
        //   this._value = event.target.value;
        if (this.type == 'percent')
            event.target.pctValue = (parseFloat(event.target.value) / 100) || null;
        this.input.emit(event);
    };
    MdNumberComponent.prototype.onChanged = function (event) {
        //   this._value = event.target.value;
        if (this.type == 'percent')
            event.target.pctValue = (parseFloat(event.target.value) / 100) || null;
        this.changed.emit(event);
    };
    MdNumberComponent.prototype.onFocus = function (event) {
        this._isFocused = true;
        this.focus.emit(event);
    };
    MdNumberComponent.prototype.onBlur = function (event) {
        this._isFocused = false;
        // this._value = event.target.value;
        if (this.type == 'percent')
            event.target.pctValue = (parseFloat(event.target.value) / 100) || null;
        this.blur.emit(event);
    };
    return MdNumberComponent;
}());
export { MdNumberComponent };
