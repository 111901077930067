import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatMenuTrigger} from '@angular/material';

@Component({
  selector: 'app-contact-chip',
  templateUrl: './contact-chip.component.html',
  styleUrls: ['./contact-chip.component.scss']
})
export class ContactChipComponent implements OnInit {
  @Input('size') size:string = 'sm';
  @Input('person') person:any;
  @Input('removable') removable:boolean = false;
  @Input('showName') showName:boolean = true;
  @Input('showPic') showPic:boolean = false;
  @Input('max-width') maxWidth:string = '';
  @Input('customer-id') customerId:string = '';
  @Output('remove') removeEvent:EventEmitter<any> = new EventEmitter();
  trigger: MatMenuTrigger;

  colorPicker = {
  "A": "#F44336",
  "B": "#E91E63",
  "C": "#9C27B0",
  "D": "#673AB7",
  "E": "#3F51B5",
  "F": "#2196F3",
  "G": "#039BE5",
  "H": "#0097A7",
  "I": "#009688",
  "J": "#43A047",
  "K": "#689F38",
  "L": "#EF6C00",
  "M": "#FF5722",
  "N": "#795548",
  "O": "#FF5252",
  "P": "#FF4081",
  "Q": "#D500F9",
  "R": "#7C4DFF",
  "S": "#536DFE",
  "T": "#2979FF",
  "U": "#0091EA",
  "V": "#00E5FF",
  "W": "#00796B",
  "X": "#00E676",
  "Y": "#AEEA00",
  "Z": "#FFD600"
}

  constructor() { }

  ngOnInit() {
  }

  openMenu() {
    this.trigger.openMenu();
  }

  removeClick($event){
    $event.stopPropagation();
    this.removeEvent.emit($event);
  }
}

