import { Injectable, EventEmitter } from '@angular/core';
//import {Observable, Subject, Subscription} from 'rxjs/Rx';
import { Ijobs } from './jobs.service'

@Injectable()
export class CoaReportCartService {
  _coaJobs: Ijobs[] = [];
  coaJobsEmiter = new EventEmitter()
  count = new EventEmitter()
  constructor() { }

  add(_job:Ijobs){
    if(this._coaJobs.findIndex(__job => __job._id == _job._id) < 0){
      this._coaJobs.push(_job);
      this.coaJobsEmiter.emit(this._coaJobs);
      this.count.emit(this._coaJobs.length);
    }
  }

  remove(_jobId){
    let i = this._coaJobs.findIndex(__job => __job._id == _jobId);
    if(i > -1){
      this._coaJobs.splice(i,1);
      this.coaJobsEmiter.emit(this._coaJobs);
      this.count.emit(this._coaJobs.length);
    }
  }

  removeAll(){
    this._coaJobs = [];
    this.coaJobsEmiter.emit(this._coaJobs);
    this.count.emit(this._coaJobs.length);
  }

  coaJobs(){
    let me = this;
    setTimeout(function() {
      me.coaJobsEmiter.emit(me._coaJobs)
    }, 0);
    return me.coaJobsEmiter
  }

  getAllCOAJobs(){
    let me = this;
    return me._coaJobs;
  }
}
