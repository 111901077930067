//import { environment } from './../environments/environment';
//import { AuthGuardPrintService } from './services/auth-guard-print.service';
import { BarcodeScannerService } from './services/barcode-scanner.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Renderer } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
//import { RouterOutlet } from '@angular/router';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
// import { MaterialModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppComponent } from './app.component';
import { SigninComponent } from './signin/signin.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import {MyServerService} from './services/my-server.service';
import {UsersService} from './services/users.service';
import { AuthGuardClientService } from './services/auth-guard-client.service';
import { BarcodeCartService } from './services/barcode-cart.service'
import { CoaReportCartService } from './services/coa-report-cart.service'

//import { MainToolbarComponent } from './main-toolbar/main-toolbar.component';
//import { SettingsSidenavComponent } from './settings-sidenav/settings-sidenav.component';
//import { SiteSidenavComponent } from './site-sidenav/site-sidenav.component';
import { MatCheckboxModule, MatCardModule, MatButtonModule, MatIconModule  } from '@angular/material'

import { DirectivesModule } from './directives/directives.module';
import 'hammerjs';

import { CheckForUpdateService } from './services/sw.service';
import { environment } from '../environments/environment'

import { ErrorHandler } from "@angular/core";
import { ReportsComponent } from './reports/reports.component';
import { HttpClientModule } from '@angular/common/http';
//import { QrButtonComponent } from './qr-button/qr-button.component';

declare function cwr(operation: string, payload: any): void;

export class CwrErrorHandler implements ErrorHandler {
  handleError(error: any) {
    if(environment.production) cwr('recordError', error);
    else throw error;
  }
}

//import { ActionContextMenuComponent } from './bia-portal/action-admin/action-item/action-context-menu/action-context-menu.component';




@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    ReportsComponent,
    //QrButtonComponent,
    // MainToolbarComponent,
    // SettingsSidenavComponent,
    // SiteSidenavComponent,
    //ActionContextMenuComponent
  ],
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}),
    CommonModule,
    FormsModule,
    HttpModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // MaterialModule,
    MatCheckboxModule, MatCardModule, MatButtonModule, MatIconModule,
    DirectivesModule.forRoot(),
    FlexLayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    HttpClientModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    MyServerService,
    UsersService,
    AuthGuardClientService,
    BarcodeCartService,
    BarcodeScannerService,
    CoaReportCartService,
    CheckForUpdateService,
    {
      provide: ErrorHandler,
      useClass: CwrErrorHandler
    },
    //AuthGuardPrintService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }



