<div style="background: white;" >
  <a style="margin: 4px; display: inline-block;" [routerLink]="['']"><img src="/assets/logo_200.jpg" class="logo"></a>
  <h2 style="text-align: center;" >
    Bia Diagnostics Confirmation and Validation of Report {{name}}
  </h2>
</div>

<div *ngIf="!!errorMsg" >
  <h1 style="color: Red; text-align: center;background: white;" >
    {{errorMsg}}
  </h1>
</div>
<iframe style="flex-grow: 1;" *ngIf="!!url"  [src]="url"></iframe>