var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
var ReportsComponent = /** @class */ (function () {
    function ReportsComponent(ActivatedRoute, http, sanitizer, cd) {
        this.ActivatedRoute = ActivatedRoute;
        this.http = http;
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.code = '';
        this.id = '';
        this.name = '';
    }
    ReportsComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var reportInfo;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.code = this.ActivatedRoute.snapshot.params['code'];
                        this.id = this.ActivatedRoute.snapshot.params['id'];
                        this.name = this.ActivatedRoute.snapshot.params['name'];
                        return [4 /*yield*/, this.http.post(environment.sever + "/reportsInfo", {
                                id: this.id,
                                code: this.code,
                                name: this.name
                            }).toPromise()];
                    case 1:
                        reportInfo = _a.sent();
                        if (reportInfo.surl)
                            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(reportInfo.surl);
                        if (reportInfo.error)
                            this.errorMsg = "The report could not be found please contatct Bia Diagnostics.";
                        if (reportInfo.latestPrint)
                            this.errorMsg = "You do not have the latest version of this report. Find the latest version below.";
                        console.log(reportInfo);
                        this.cd.markForCheck();
                        return [2 /*return*/];
                }
            });
        });
    };
    return ReportsComponent;
}());
export { ReportsComponent };
