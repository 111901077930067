import { Injectable } from '@angular/core';

@Injectable()
export class DialogDataService {
  private _data:any;
  set data(_val) {
    this._data = _val;
  }

  get data() {
    return this._data;
  }

  constructor() { }

}
