/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reports.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./reports.component";
import * as i5 from "@angular/common/http";
import * as i6 from "@angular/platform-browser";
var styles_ReportsComponent = [i0.styles];
var RenderType_ReportsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportsComponent, data: {} });
export { RenderType_ReportsComponent as RenderType_ReportsComponent };
function View_ReportsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["style", "color: Red; text-align: center;background: white;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMsg; _ck(_v, 2, 0, currVal_0); }); }
function View_ReportsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["style", "flex-grow: 1;"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 0, 0, currVal_0); }); }
export function View_ReportsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["style", "background: white;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["style", "margin: 4px; display: inline-block;"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "logo"], ["src", "/assets/logo_200.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" Bia Diagnostics Confirmation and Validation of Report ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportsComponent_1)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportsComponent_2)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 3, 0, ""); _ck(_v, 2, 0, currVal_2); var currVal_4 = !!_co.errorMsg; _ck(_v, 8, 0, currVal_4); var currVal_5 = !!_co.url; _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.name; _ck(_v, 6, 0, currVal_3); }); }
export function View_ReportsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reports", [], null, null, null, View_ReportsComponent_0, RenderType_ReportsComponent)), i1.ɵdid(1, 114688, null, 0, i4.ReportsComponent, [i2.ActivatedRoute, i5.HttpClient, i6.DomSanitizer, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportsComponentNgFactory = i1.ɵccf("app-reports", i4.ReportsComponent, View_ReportsComponent_Host_0, {}, {}, []);
export { ReportsComponentNgFactory as ReportsComponentNgFactory };
