var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { environment } from './../../environments/environment';
import { EventEmitter } from '@angular/core';
import { Http, BaseRequestOptions } from '@angular/http';
import { serverRx } from './rxServer';
var MyServerService = /** @class */ (function (_super) {
    __extends(MyServerService, _super);
    function MyServerService(Http) {
        var _this = _super.call(this, environment.sever) || this;
        _this.Http = Http;
        _this.email = "";
        _this.currentUser = false;
        _this.loggedIn = new EventEmitter();
        var me = _this;
        me.email = localStorage.getItem('email') || "";
        var keepMeLoggedInKey = localStorage.getItem('keepMeLoggedInKey');
        //console.log(keepMeLoggedInKey)
        if (keepMeLoggedInKey) {
            var req = new BaseRequestOptions();
            req.headers.set("Content-Type", "application/json");
            req.method = "post";
            req.url = environment.sever + "/loginCode";
            me.Http.post(environment.sever + "/loginCode", { email: me.email, keepMeLoggedInKey: keepMeLoggedInKey }, req).toPromise().then(function (res) {
                if (res.json().result == 'bad code') {
                    localStorage.removeItem('keepMeLoggedInKey');
                    window.open(environment.sever, "_self");
                }
                me.connect();
                me.testGetUser().then(function (_user) {
                    me.isLoggedIn = true;
                    me.currentUser = _user;
                    me.loggedIn.emit(_user);
                });
            });
        }
        else
            me.isLoggedIn = false;
        return _this;
    }
    MyServerService.prototype.rebootServer = function () {
        return this.publicFunction('reBoot', {}).toPromise();
    };
    MyServerService.prototype.testGetUser = function () {
        var me = this;
        return me.publicFunction('testUser').toPromise();
    };
    MyServerService.prototype.sendLogInCode = function (email) {
        var me = this;
        var req = new BaseRequestOptions();
        me.email = email;
        req.headers.set("Content-Type", "application/json");
        req.method = "post";
        req.url = environment.sever + "/sendCode";
        return me.Http.post(environment.sever + "/sendCode", { email: email }, req).toPromise().then(function (res) {
            var body = res.json();
            return (body || {}).result;
        });
    };
    MyServerService.prototype.login = function (code, keepMeLoggedIn) {
        var me = this;
        me.isLoggedIn = undefined;
        var req = new BaseRequestOptions();
        req.headers.set("Content-Type", "application/json");
        req.method = "post";
        req.url = environment.sever + "/loginCode";
        return me.Http.post(environment.sever + "/loginCode", { code: code, email: me.email, keepMeLoggedIn: keepMeLoggedIn }, req).toPromise().then(function (res) {
            var body = res.json() || {};
            if (body.result == 'logged in') {
                if (keepMeLoggedIn) {
                    localStorage.setItem('keepMeLoggedInKey', body.keepMeLoggedInKey);
                    localStorage.setItem('email', me.email);
                }
                me.connect();
                return me.testGetUser().then(function (_user) {
                    me.isLoggedIn = true;
                    me.currentUser = _user;
                    me.loggedIn.emit(_user);
                    return true;
                });
            }
            else
                return false;
        });
    };
    MyServerService.prototype.logout = function (url) {
        var me = this;
        me.isLoggedIn = false;
        localStorage.removeItem('keepMeLoggedInKey');
        var req = new BaseRequestOptions();
        req.headers.set("Content-Type", "application/json");
        req.method = "post";
        req.url = environment.sever + "/logout";
        return me.Http.post(environment.sever + "/logout", {}, req).toPromise().then(function () {
            window.open(url || environment.sever, "_self");
        });
    };
    return MyServerService;
}(serverRx));
export { MyServerService };
