import { environment } from './../../environments/environment';
import {Injectable,Inject, EventEmitter} from '@angular/core';
import {Http, Response,RequestOptions,BaseRequestOptions} from '@angular/http';
import {serverRx} from './rxServer';
//import * as rx from 'rxjs/Rx';
//import 'rxjs/add/operator/toPromise';
//import {Observable, Subject, Subscription} from 'rxjs/Rx';

declare const localStorage;

@Injectable()
export class MyServerService extends serverRx {
  email = "";
  currentUser:any= false;
  isLoggedIn;
  redirectUrl: string;
  loggedIn = new EventEmitter();

  constructor(@Inject(Http) private Http:Http){
    super(environment.sever);

    let me = this;
    me.email = localStorage.getItem('email') || "";
    let keepMeLoggedInKey = localStorage.getItem('keepMeLoggedInKey');
    //console.log(keepMeLoggedInKey)
    if(keepMeLoggedInKey){
      let req = new BaseRequestOptions();
      req.headers.set("Content-Type","application/json");
      req.method = "post";
      req.url = environment.sever + "/loginCode";
      me.Http.post(environment.sever + "/loginCode",{email:me.email,keepMeLoggedInKey:keepMeLoggedInKey},req).toPromise().then((res)=>{
        if(res.json().result == 'bad code'){
          localStorage.removeItem('keepMeLoggedInKey');
          window.open(environment.sever,"_self")
        }
        me.connect();
        me.testGetUser().then(_user =>{
          me.isLoggedIn = true;
          me.currentUser = _user;
          me.loggedIn.emit(_user);
        })
      })
    }
    else me.isLoggedIn = false;
  }

  rebootServer(){
    return this.publicFunction('reBoot',{}).toPromise()
  }

  testGetUser(){
    let me = this;
    return me.publicFunction('testUser').toPromise()
  }

  sendLogInCode(email){
    let me = this;
    let req = new BaseRequestOptions();
    me.email = email
    req.headers.set("Content-Type","application/json");
    req.method = "post";
    req.url = environment.sever + "/sendCode";
    return me.Http.post(environment.sever + "/sendCode",{email:email},req).toPromise().then((res)=>{
        let body = res.json();
        return (body || {}).result;
      })
  }

  login(code,keepMeLoggedIn){
    let me = this;
    me.isLoggedIn = undefined;
    let req = new BaseRequestOptions();
    req.headers.set("Content-Type","application/json");
    req.method = "post";
    req.url = environment.sever + "/loginCode";
    return me.Http.post(environment.sever + "/loginCode",{code:code,email:me.email,keepMeLoggedIn:keepMeLoggedIn},req).toPromise().then((res)=>{
        let body = res.json() || {};
        if(body.result == 'logged in'){
          if(keepMeLoggedIn){
              localStorage.setItem('keepMeLoggedInKey', body.keepMeLoggedInKey);
              localStorage.setItem('email', me.email);
          }
          me.connect();
          return me.testGetUser().then(_user =>{
            me.isLoggedIn = true;
            me.currentUser = _user;
            me.loggedIn.emit(_user);
            return true;
          })
        }
        else return false;

    })
  }

  logout(url?:string) {
    let me = this;
    me.isLoggedIn = false;
    localStorage.removeItem('keepMeLoggedInKey')
    let req = new BaseRequestOptions();
    req.headers.set("Content-Type","application/json");
    req.method = "post";
    req.url = environment.sever + "/logout";
    return me.Http.post(environment.sever + "/logout",{},req).toPromise().then(()=>{
      window.open(url || environment.sever,"_self")
    })
  }
}
