import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatMenuTrigger} from '@angular/material';

@Component({
  selector: 'app-user-customer-chip',
  templateUrl: './user-customer-chip.component.html',
  styleUrls: ['./user-customer-chip.component.scss']
})
export class UserCustomerChipComponent implements OnInit {
  @Input('size') size:string = 'sm';
  @Input('user') user:any;
  @Input('customer') customer:any;
  @Input('removable') removable:boolean = false;
  @Input('max-width') maxWidth:string = '';
  @Output('changed') changedEvent:EventEmitter<any> = new EventEmitter();
  @Output('remove') removeEvent:EventEmitter<any> = new EventEmitter();
  trigger: MatMenuTrigger;

  constructor() { }

  ngOnInit() {
  }

  openMenu() {
    this.trigger.openMenu();
  }

  facilityChanged(facilityId, value){
    let me = this, event = {};
    event = {
      user: me.user,
      facilityId: facilityId,
      value: value
    };
    me.changedEvent.emit(event);
  }

  trackFacilitiesBy(facility){
    return facility._id;
  }

  removeClick(customerId, $event){
    let me = this, event = {};
    $event.stopPropagation();
    event = {
      user: me.user,
      customerId: customerId
    };
    this.removeEvent.emit(event);
  }
}

