
<h2 style="margin-top: 0px;">
  {{title}}
</h2>
<mat-icon style="float: right;margin-top: -45px;font-size: 48px;" *ngIf="canReadNFC"  aria-label="nfc">nfc</mat-icon>
<div style="padding-bottom: 16px;font-weight: 600;" >
  Scanned: {{type || ''}} {{result || "none"}}
</div>
<div style="position:relative;border-radius: 8px;">
  <video style="max-width: 100%; " #video></video>
  <div class="qrSqare">
    <div class="subSqare"  [class.has-code]="hasCode">
      <div class="subSqare-top-left" [class.has-code]="hasCode" ></div>
      <div class="subSqare-top-right" [class.has-code]="hasCode" ></div>
      <div class="subSqare-bottom-right" [class.has-code]="hasCode" ></div>
      <div class="subSqare-bottom-left" [class.has-code]="hasCode" ></div>
    </div>
  </div>
</div>

<button style="float: left;margin-top: -25px;margin-left: -25px;" mat-fab (click)="togelCamera()">
  <mat-icon aria-label="cameraswitch">cameraswitch</mat-icon>
</button>
<button style="float: right;margin-top: -25px;margin-right: -25px;" mat-fab (click)="close()">
  <mat-icon aria-label="cameraswitch">close</mat-icon>
</button>

