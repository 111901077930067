import { fourPFitService } from './../../services/4p-fit.service';
import { OnInit, ElementRef, OnChanges } from '@angular/core';
import * as d3 from "d3";
var ChartComponent = /** @class */ (function () {
    function ChartComponent(fourPFitService) {
        this.fourPFitService = fourPFitService;
        this._max = 40;
        this._maxY = 4;
        this.standards = [];
        this.samples = [];
        this._curve = [];
        this._params = {};
    }
    Object.defineProperty(ChartComponent.prototype, "params", {
        set: function (_value) {
            this._curve = [];
            this._params = _value;
            if (this.standards && this.standards.length > 0) {
                var sx = this.standards.map(function (s) { return parseFloat(s.expected); });
                this._max = Math.ceil(Math.max.apply(Math, sx));
                var sy = this.standards.map(function (s) { return parseFloat(s.OD); });
                this._maxY = Math.ceil(Math.max.apply(Math, sy));
                if (_value && !!_value.A && !!_value.B) {
                    console.log('_value');
                    // norem
                    if (sy[0] < this._maxY)
                        for (var i = 0; i < this._maxY; i += .01) {
                            //console.log('norm')
                            var x = this.fourPFitService.MyRawEquation([_value.A, _value.B, _value.C, _value.D], i);
                            this._curve.push([x, i]);
                            if (x > this._max)
                                break;
                        }
                    //sandwitch
                    else
                        for (var i = this._maxY; i > 0; i -= .01) {
                            //console.log('sandwitch')
                            var x = this.fourPFitService.MyRawEquation([_value.A, _value.B, _value.C, _value.D], i);
                            this._curve.push([x, i]);
                            if (x < 0)
                                break;
                        }
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    ChartComponent.prototype.ngOnInit = function () {
        var sx = this.standards.map(function (s) { return parseFloat(s.expected); });
        var sy = this.standards.map(function (s) { return parseFloat(s.OD); });
        if (sy[0] < this._maxY) {
            //console.log('norm')
            for (var i = 0; i < this._max; i += .01) {
                this._curve.push([i, 0]);
            }
        }
        else {
            console.log('sandwitch');
            for (var i = this._maxY; i > 0; i -= .01) {
                this._curve.push([i, 0]);
            }
        }
        this._svg = d3.select(this.svg.nativeElement);
        var margin = { top: 30, right: 20, bottom: 30, left: 80 }, width = 600 - margin.left - margin.right, height = 270 - margin.top - margin.bottom;
        var x = d3.scaleLinear().range([0, width]);
        var y = d3.scaleLinear().range([height, 0]);
        var stds = (this.standards || []).map(function (cell) {
            return [cell.expected, cell.OD];
        }); // [[1,1],[2,2],[3,3]]
        var samps = (this.samples || []).map(function (cell) {
            return [cell.expected, cell.OD];
        });
        var dataB = this._curve; //[[0,0],[.5,0],[.7,.5],[1,1],[1.2,1.7],[2,2],[2.1,2.6],[2.6,2.8],[3,3]]
        // Define the axes
        // var xAxis = d3.svg.axis().scale(x)
        // .orient("bottom").ticks(5);
        var xAxis = d3.axisBottom(x);
        // var yAxis = d3.svg.axis().scale(y)
        // .orient("left").ticks(5);
        var yAxis = d3.axisLeft(y);
        // Define the line
        // var valueline = d3.svg.line()
        //     .x(function(d) { return x(d.date); })
        //     .y(function(d) { return y(d.close); });
        var valueline = d3.line().x(function (d) { return x(d[0]); }).y(function (d) { return y(d[1]); });
        // Adds the svg canvas
        //this._svg = d3.select(this.svg.nativeElement)
        // .append("svg")
        //     .attr("width", width + margin.left + margin.right)
        //     .attr("height", height + margin.top + margin.bottom)
        this._svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        // Get the data
        // data.forEach(function(d) {
        //     d.date = parseDate(d.date);
        //     d.close = +d.close;
        // });
        // Scale the range of the data
        x.domain([0, this._max]);
        y.domain([0, 3]);
        // Add the valueline path.
        this._svg.append("path").datum(dataB)
            .attr("class", "line")
            .attr("style", "stroke:steelblue;stroke-width: 1.5;fill: none;")
            .attr("d", valueline);
        // Add the scatterplot
        this._svg.append("g").attr("class", "stds")
            .data(stds)
            .enter().append("circle")
            .attr("r", 3.5)
            .attr("cx", function (d) { return x(d[0]); })
            .attr("cy", function (d) { return y(d[1]); }).attr("style", "fill:cornflowerblue;");
        this._svg.append("g").attr("class", "samps")
            .data(samps)
            .enter().append("circle")
            .attr("r", 2.5)
            .attr("cx", function (d) { return x(d[0]); })
            .attr("cy", function (d) { return y(d[1]); }).attr("style", "fill:red;");
        // Add the X Axis
        this._svg.append("g")
            .attr("class", "x axis")
            .attr("transform", "translate(0," + height + ")")
            .style("font-size", "8px")
            .style("font-weight", "700")
            .call(xAxis);
        // Add the Y Axis
        this._svg.append("g")
            .attr("class", "y axis")
            .style("font-size", "8px")
            .style("font-weight", "700")
            .call(yAxis);
        this._svg.selectAll(".xaxis text") // select all the text elements for the xaxis
            .attr("transform", function (d) {
            return "translate(" + height * -2 + "," + height + ")rotate(-45)";
        });
        // now add titles to the axes
        this._svg.append("text")
            .attr("text-anchor", "middle") // this makes it easy to centre the text as the transform is applied to the anchor
            .attr("transform", "translate(" + (-(2.6 * margin.left) / 8) + "," + (height / 2) + ")rotate(-90)") // text is drawn off the screen top left, move down and out and rotate
            .style("font-size", "10px")
            .style("font-weight", "700")
            .text("O.D.");
        this._svg.append("text")
            .attr("text-anchor", "middle") // this makes it easy to centre the text as the transform is applied to the anchor
            .attr("transform", "translate(" + (width / 2) + "," + ((height + margin.top + margin.bottom) - (margin.top + margin.bottom) / 3) + ")") // centre below axis
            .style("font-size", "10px")
            .style("font-weight", "700")
            .text("Concentration");
    };
    ChartComponent.prototype.ngOnChanges = function () {
        if (this._params && !!this._params.A) {
            this._curve = [];
            if (this.standards && this.standards.length > 0) {
                var sx = this.standards.map(function (s) { return parseFloat(s.expected); });
                this._max = Math.ceil(Math.max.apply(Math, sx));
                var sMin = Math.min.apply(Math, sx);
                var sy = this.standards.map(function (s) { return parseFloat(s.OD); });
                var mY = Math.max.apply(Math, sy);
                this._maxY = Math.ceil(mY);
                if (this._params && !!this._params.A && !!this._params.B) {
                    if (sy[0] < mY) {
                        console.log('norm');
                        for (var i = 0; i < this._maxY; i += .01) {
                            var x_1 = this.fourPFitService.MyRawEquation([this._params.A, this._params.B, this._params.C, this._params.D], i);
                            this._curve.push([x_1, i]);
                            if (x_1 > this._max)
                                break;
                        }
                    }
                    else {
                        console.log('sandwitch');
                        for (var i = this._maxY; i > 0; i -= .01) {
                            var x_2 = this.fourPFitService.MyRawEquation([this._params.A, this._params.B, this._params.C, this._params.D], i);
                            this._curve.push([x_2, i]);
                            if (x_2 > this._max)
                                break;
                            //if(x < sMin) break;
                        }
                    }
                }
            }
            var margin = { top: 30, right: 20, bottom: 30, left: 70 }, width = 600 - margin.left - margin.right, height = 270 - margin.top - margin.bottom;
            var stds = (this.standards || []).map(function (cell) {
                return [cell.expected, cell.OD];
            });
            var samps = (this.samples || []).map(function (cell) {
                return [cell.expected, cell.OD];
            });
            var x_3 = d3.scaleLinear().range([0, width]);
            var y_1 = d3.scaleLinear().range([height, 0]);
            var valueline = d3.line().x(function (d) { return x_3(d[0]); }).y(function (d) { return y_1(d[1]); });
            x_3.domain([0, d3.max([d3.max(this._curve, function (d) { return d[0]; }), d3.max(samps, function (d) { return d[0]; })])]);
            y_1.domain([0, d3.max([d3.max(this._curve, function (d) { return d[1]; }), d3.max(samps, function (d) { return d[1]; })]) + .5]);
            var xAxis = d3.axisBottom(x_3);
            var yAxis = d3.axisLeft(y_1);
            var line = this._svg.select(".line"); // change the line
            line.transition().duration(1750)
                .attr("d", valueline(this._curve));
            var stdDots = this._svg.select(".stds").selectAll("circle").data(stds);
            stdDots.transition().duration(1750).attr("r", 3.5)
                .attr("cx", function (d) { return x_3(d[0]); })
                .attr("cy", function (d) { return y_1(d[1]); });
            stdDots.enter()
                .append("circle")
                .attr("r", 3.5)
                .attr("cx", function (d) { return x_3(d[0]); })
                .attr("cy", function (d) { return y_1(d[1]); }).attr("style", "fill:cornflowerblue;");
            var sampDots = this._svg.select(".samps").selectAll("circle").data(samps);
            sampDots.transition().duration(1750).attr("r", 2.5)
                .attr("cx", function (d) { return x_3(d[0]); })
                .attr("cy", function (d) { return y_1(d[1]); });
            sampDots.enter()
                .append("circle")
                .attr("r", 2.5)
                .attr("cx", function (d) { return x_3(d[0]); })
                .attr("cy", function (d) { return y_1(d[1]); }).attr("style", "fill:red;");
            this._svg.selectAll(".x.axis").transition() // change the x axis
                .duration(1750)
                .call(xAxis);
            this._svg.selectAll(".y.axis").transition() // change the y axis
                .duration(1750)
                .call(yAxis);
        }
    };
    return ChartComponent;
}());
export { ChartComponent };
