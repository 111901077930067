<!--<app-main-toolbar style="z-index: 10;" *ngIf="signedIn" [sticky] [sticky-start]="1" (settingsSideNav)="toggleSettingsSideNav()" (siteSideNav)="toggleSiteSideNav()"></app-main-toolbar>

<mat-sidenav-layout style="transform:none;" fxLayout="column" fxFlex >
    <mat-sidenav #end align="end" [opened]="settingsSideNav" (open)="settingsSideNav = true;" (close)="settingsSideNav = false;">
        <app-settings-sidenav (settingsSideNav)="toggleSettingsSideNav($event)"></app-settings-sidenav>
    </mat-sidenav>
    <mat-sidenav #start align="start" [opened]="siteSideNav" (open)="siteSideNav = true;" (close)="siteSideNav = false;">
        <app-site-sidenav (siteSideNav)="toggleSiteSideNav($event)"></app-site-sidenav>
    </mat-sidenav>
    <div class="main-content" >
        <router-outlet></router-outlet>
    </div>
</mat-sidenav-layout>-->
<router-outlet></router-outlet>
