var DialogDataService = /** @class */ (function () {
    function DialogDataService() {
    }
    Object.defineProperty(DialogDataService.prototype, "data", {
        get: function () {
            return this._data;
        },
        set: function (_val) {
            this._data = _val;
        },
        enumerable: true,
        configurable: true
    });
    return DialogDataService;
}());
export { DialogDataService };
