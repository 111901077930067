import { SampleSubmissionRowComponent } from './../client-portal/sample-submission-forms/sample-submission-form/sample-submission-row/sample-submission-row.component';
import { BarcodeScannerDirective } from './barcode-scanner.directive';
import { BarcodeScannerService } from './../services/barcode-scanner.service';
import { ChartComponent } from './../client-portal/chart/chart.component';
import { NgModule, ModuleWithProviders  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogModule, MatAutocompleteModule,MatCheckboxModule, MatDatepickerModule, MatInputModule, MatRadioModule, MatSelectModule,
  MatSliderModule, MatSlideToggleModule, MatMenuModule, MatSidenavModule, MatToolbarModule, MatListModule, MatGridListModule, MatCardModule,
  MatStepperModule, MatTabsModule, MatExpansionModule, MatButtonModule, MatButtonToggleModule, MatChipsModule, MatIconModule,
  MatProgressSpinnerModule, MatProgressBarModule, MatTooltipModule, MatSnackBarModule, MatTableModule, MatSortModule, MatPaginatorModule,
  MatNativeDateModule  } from '@angular/material'

import { AutoSize } from './auto-size.directive';
import { sticky } from './sticky.directive';
import { TestDirective } from './test.directive';

import { TitleCasePipe } from './title-case.pipe';

import { MdDateComponent } from '../md-date/md-date.component';
import { MdNumberComponent } from '../md-number/md-number.component';
import { MdSelectComponent } from '../md-select/md-select.component';
import { MdTextareaComponent } from '../md-textarea/md-textarea.component';
import { BarcodeComponent } from '../barcode/barcode.component';
import { AutoCompleteComponent } from '../auto-complete/auto-complete.component';
import { ContactChipComponent } from '../chips/contact-chip/contact-chip.component';
import { CustomerChipComponent } from '../chips/customer-chip/customer-chip.component';
import { UserCustomerChipComponent } from '../chips/user-customer-chip/user-customer-chip.component';

// import { MaterialModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { QrScannerComponent } from '../bia-portal/qr-scanner/qr-scanner.component';
import { QrButtonComponent } from '../qr-button/qr-button.component';
import { DialogDataService } from 'app/services/dialog-data.service';

//import { ActionContextMenuComponent } from '../bia-portal/action-admin/action-item/action-context-menu/action-context-menu.component';

const comps =[
  AutoSize,sticky,TestDirective,TitleCasePipe,
  MdDateComponent, MdNumberComponent, MdSelectComponent, MdTextareaComponent, BarcodeComponent,AutoCompleteComponent,
  ContactChipComponent, CustomerChipComponent, UserCustomerChipComponent, ChartComponent, BarcodeScannerDirective,SampleSubmissionRowComponent, QrScannerComponent, QrButtonComponent//, ActionContextMenuComponent
]

@NgModule({
  imports: [CommonModule, FormsModule, FlexLayoutModule, MatDialogModule, MatAutocompleteModule,MatCheckboxModule, MatDatepickerModule, MatInputModule, MatRadioModule, MatSelectModule,
    MatSliderModule, MatSlideToggleModule, MatMenuModule, MatSidenavModule, MatToolbarModule, MatListModule, MatGridListModule, MatCardModule,
    MatStepperModule, MatTabsModule, MatExpansionModule, MatButtonModule, MatButtonToggleModule, MatChipsModule, MatIconModule,
    MatProgressSpinnerModule, MatProgressBarModule, MatTooltipModule, MatSnackBarModule, MatTableModule, MatSortModule, MatPaginatorModule,
    MatNativeDateModule],
  providers:[DialogDataService],
  exports: comps,
  declarations: comps,
  entryComponents: [QrScannerComponent]
})
export class DirectivesModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: DirectivesModule,
      providers: []
    };
  }
}