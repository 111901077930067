import {Component,Input,Output,EventEmitter} from '@angular/core';

@Component({
  selector: 'app-md-date',
  templateUrl: './md-date.component.html',
  styleUrls: ['./md-date.component.scss']
})
export class MdDateComponent {
    @Input('value') value:any;
    @Input('valueAsDate') set valueAsDate(_val:any){
        if(_val) {
            this._type = 'date';
            this._valueAsDate = new Date(_val);
        }
        else {
            this._type = 'text';
            this._valueAsDate = null;
        }
    };
    @Input('placeholder') placeholder:string;
    @Input('float') float:boolean = true;
    @Input('min') set min(_val:any){
        if(_val) {
            let minDate = new Date(_val);
            this.min = minDate.getFullYear() + '-' + this.addLeadingZeros(minDate.getMonth() + 1) + '-' + this.addLeadingZeros(minDate.getDate());;
        }
    };
    @Input('max') set max(_val:any){
        if(_val) {
            let maxDate = new Date(_val);
            this.max = maxDate.getFullYear() + '-' + this.addLeadingZeros(maxDate.getMonth() + 1) + '-' + this.addLeadingZeros(maxDate.getDate());;
        }
    };
    @Input() disabled: boolean = false;
    @Input('required') required:boolean;

    @Output('input') input:EventEmitter<any> = new EventEmitter();
    @Output('changed') changed:EventEmitter<any> = new EventEmitter();
    @Output('focus') focus:EventEmitter<any> = new EventEmitter();
    @Output('blur') blur:EventEmitter<any> = new EventEmitter();

    _isFocused = false;
    _type = 'text';
    _valueAsDate;

    constructor(){

    }

    addLeadingZeros(_val:number){
        let _valstr = _val.toString();
        if(_valstr.length <2) return '0' + _valstr;
        else return _valstr;
    }

    onInput(event){
        this.input.emit(event);
    }

    onChanged(event) {
       this.changed.emit(event);
    }

    onFocus(event) {
       this._type = 'date';
       this._isFocused = true;
       this.focus.emit(event);
    }

    onBlur(event) {
       //if(!this._valueAsDate) this._type = 'text';
        if(event.target.valueAsDate) {
            this._type = 'date';
            this._valueAsDate = new Date(event.target.valueAsDate);
        }
        else {
            this._type = 'text';
            this._valueAsDate = null;
        }
       this._isFocused = false;
       this.blur.emit(event);
    }
}
