import { Component, EventEmitter, Input, OnInit , Output} from '@angular/core';
import { MatDialog } from '@angular/material';
import { QrScannerComponent } from '../bia-portal/qr-scanner/qr-scanner.component';
import { DialogDataService } from '../services/dialog-data.service'

@Component({
  selector: 'app-qr-button',
  templateUrl: './qr-button.component.html',
  styleUrls: ['./qr-button.component.scss']
})
export class QrButtonComponent implements OnInit {
  @Input() title: string;
  @Input() disabled: boolean = false;
  @Output('selected') selected:EventEmitter<any> = new EventEmitter()

  constructor(public dialog: MatDialog, private DialogDataService:DialogDataService) { } //, private DialogDataService:DialogDataService

  ngOnInit() {
  }

  openQrDialog(){
    if(this.disabled) return;
    this.DialogDataService.data = {
      title: this.title,
      forField: true
    }
    let qrDialog = this.dialog.open(QrScannerComponent, {
      //width: '90%',
      data:{
        title: this.title,
        forField: true
      }
    });

    qrDialog.afterClosed().subscribe(result => {
      this.selected.emit(result);
    })
  }

}


