var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit, ElementRef, OnChanges } from '@angular/core';
import { toCanvas } from 'qrcode';
var BarcodeComponent = /** @class */ (function () {
    function BarcodeComponent() {
        this.displayValue = true;
        this.textPosition = 'center';
    }
    BarcodeComponent.prototype.ngOnInit = function () {
        this.render();
    };
    BarcodeComponent.prototype.ngOnChanges = function () {
        this.render();
    };
    BarcodeComponent.prototype.ngAfterViewInit = function () {
        //this.render()
    };
    BarcodeComponent.prototype.render = function () {
        if (this.qrCanvas && this.qrCanvas.nativeElement) {
            var me = this;
            var options = { scale: 2 };
            if (me.width)
                options.width = parseFloat(me.width);
            if (me.height)
                options.height = parseFloat(me.height);
            if (me.displayValue !== undefined)
                options.displayValue = me.displayValue;
            if (me.font)
                options.font = me.font;
            if (me.textAlign)
                options.textAlign = me.textAlign;
            if (me.textPosition)
                options.textPosition = me.textPosition;
            if (me.textMargin)
                options.textMargin = parseFloat(me.textMargin);
            if (me.fontSize)
                options.fontSize = parseFloat(me.fontSize);
            if (me.background)
                options.background = me.background;
            if (me.lineColor)
                options.lineColor = me.lineColor;
            if (me.margin)
                options.margin = parseFloat(me.margin);
            if (me.marginTop)
                options.marginTop = parseFloat(me.marginTop);
            if (me.marginBottom)
                options.marginBottom = parseFloat(me.marginBottom);
            if (me.marginLeft)
                options.marginLeft = parseFloat(me.marginLeft);
            if (me.marginRight)
                options.marginRight = parseFloat(me.marginRight);
            //JsBarcode(this.barcodeSvg.nativeElement, this.value || "Loading", options);
            toCanvas(this.qrCanvas.nativeElement, this.value || "Loading", __assign({ errorCorrectionLevel: 'H' }, options));
        }
    };
    return BarcodeComponent;
}());
export { BarcodeComponent };
