import { BarcodeScannerService } from './../services/barcode-scanner.service';
import { Component, OnInit, Input, Output, EventEmitter, ContentChild, TemplateRef, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class AutoCompleteComponent implements OnInit, OnChanges {
  @Input('value') value:string = '';
  @Input('key') key:string = '';
  @Input('multiline') multiline:boolean = false;
  @Input('loading') loading:boolean = false;
  @Input('items') items:any[] = [];
  @Input('placeholder') placeholder:string;
  @Input('in-modal') inModal:boolean;
  @Input('float') float:boolean;
  @Input('required') required:boolean;
  @Input('only-in-items') onlyInItems:boolean;
  @Input('clear-on-blur') clearOnBlur:boolean;
  @Input('show-no-results') showNoResults:boolean = true;
  @Input('force-value-in-items') forceValueInItems:boolean = true;
  @Input() disabled: boolean = false;
  @Input('barcode-type') barcodeType:string;
  @Output('input') input:EventEmitter<any> = new EventEmitter();
  @Output('changed') changed:EventEmitter<any> = new EventEmitter();
  @Output('selected') selected:EventEmitter<any> = new EventEmitter();
  @Output('blur') blur:EventEmitter<any> = new EventEmitter();
  @ContentChild(TemplateRef ) itemTemplate;
  @ViewChild('selector') selector:ElementRef

  _value = '';
  _highlightedItem;
  _isFocused = false;
  _barcodeEvent:any;
  constructor( private cd: ChangeDetectorRef,private BarcodeScannerService:BarcodeScannerService) { }

  ngOnInit() {

  }

  ngOnChanges(data){
    let me = this;
    if(data.value || data.key){
      if(me.key && me.value) me._value = me.value[me.key];
      else if (me.value && me._value != me.value) me._value = me.value;
    }
    if(data.items){
      if(me._highlightedItem){
        let i;
        if(me.key) i = me.items.findIndex(_item => _item[me.key] == me._highlightedItem[me.key]);
        else i = me.items.indexOf(me._highlightedItem);
        if(i >-1) me._highlightedItem = me.items[i];
      }
      else if(me.items && me.items.length > 0) {
        me._highlightedItem = me.items[0];
      }
    }
  }

  onInput(event){
      //this._value = event.target.value;


      this.input.emit(event);
      this.cd.markForCheck();



  }

  onKeypress(event){
    let me = this;

    if (event.key !== undefined) {
      switch (event.key) {
        case "ArrowDown":
          me._value = event.target.value;
          me.moveSelctionDown();
          event.preventDefault();
          break;
        case "ArrowUp":
          me._value = event.target.value;
          me.moveSelctionUp();
          event.preventDefault();
          break;
        case "Enter":
          me._value = event.target.value;
          me.onEnter();
          break;
        case "Tab":
          me._value = event.target.value;
          me.onTab();
          break;
      }
    } else if (event.keyIdentifier !== undefined) {
      switch (event.keyIdentifier) {
        case "Down":
          me._value = event.target.value;
          me.moveSelctionDown();
          event.preventDefault();
          break;
        case "Up":
          me._value = event.target.value;
          me.moveSelctionUp();
          event.preventDefault();
          break;
        case "Enter":
          me._value = event.target.value;
          me.onEnter();
          break;
        case "Tab":
          me._value = event.target.value;
          me.onTab();
          break;
      }
    } else if (event.keyCode !== undefined) {
      switch (event.keyCode) {
        case 40:
          me._value = event.target.value;
          me.moveSelctionDown();
          event.preventDefault();
          break;
        case 38:
          me._value = event.target.value;
          me.moveSelctionUp();
          event.preventDefault();
          break;
        case 13:
          me._value = event.target.value;
          me.onEnter();
          break;
        case 9:
          me._value = event.target.value;
          me.onTab();
          break;
      }
    }
  }

  moveSelctionDown(){
    let me = this;
    if(me._highlightedItem && me.items && me.items.length > 0){
      let i;
      if(me.key) i = me.items.findIndex(_item =>  _item[me.key] == me._highlightedItem[me.key] );
      else i = me.items.indexOf(me._highlightedItem)
      i++;
      if(me.items.length > i) me._highlightedItem = me.items[i];
    }
    else if(me.items && me.items.length > 0) me._highlightedItem = me.items[0];
  }

  moveSelctionUp(){
    let me = this;
    if(me._highlightedItem && me.items && me.items.length > 0){
      let i;
      if(me.key) i = me.items.findIndex(_item =>  _item[me.key] == me._highlightedItem[me.key] );
      else i = me.items.indexOf(me._highlightedItem)
      i = i - 1;
      if(-1 < i) me._highlightedItem = me.items[i];
    }
    else if(me.items && me.items.length > 0) me._highlightedItem = me.items[0];
  }


  onFocus(event) {
    let me = this;
    me._isFocused = true;
    if(me.value && me.forceValueInItems) me.items = [me.value];
    else me.items =[];
    me.input.emit(event);
    if(me.barcodeType && me.barcodeType.length > 0) {
      me._barcodeEvent = me.BarcodeScannerService.OverRidingBarcodeEvent(me.barcodeType).subscribe(_barcode=>{
        me._value = _barcode.text
        me.onInput({target:{value:_barcode.text}})

        //me.onEnter();
        me.cd.markForCheck();
      })
    }
    me.cd.markForCheck();
    //this.focus.emit(event);
  }

  onChanged(event) {
      this.changed.emit(event);
      this.cd.markForCheck();
  }

  onBlur(event) {
    let me = this;
    me._value = event.target.value;
      //setTimeout(()=>{
        me._isFocused = false;

        if(me.key) {
          if(me.items){
            let i = me.items.findIndex(_item => _item[me.key] == me._value)
            if(i > -1) me.value = me.items[i];
            else if(me.onlyInItems) me.value = undefined;
          }
          else if(me.onlyInItems) me.value = undefined;
        }
        else{
          me.value = me._value;
          if(me.items){
            let i = me.items.indexOf(me._value)
            if(i > -1) me.value = me.items[i];
            else if(me.onlyInItems) me.value = undefined;
          }
          else if(me.onlyInItems) me.value = undefined;
        }

        let _value = me._value;
        if(me.key && me.value && me.value[me.key] == me._value ) _value = me.value;
        else if(me.key && me.onlyInItems && (!me.value || me.value[me.key] != me._value)) _value = undefined;
        me.blur.emit(_value)
        if(!me.value) me._value = '';
        if(me.clearOnBlur) me._value = '';
        me.cd.markForCheck();
        if(me._barcodeEvent && me._barcodeEvent.unsubscribe) me._barcodeEvent.unsubscribe()
      //},5000)
      //this.blur.emit(event);
  }

  onTab(){
    let me = this;

    if(me._highlightedItem && me.items && me.items.length > 0){
      let i;
      if(me.key) i = me.items.findIndex(_item =>  _item[me.key] == me._highlightedItem[me.key] );
      else i = me.items.indexOf(me._highlightedItem)
      me.onSelect(me.items[i])
    }
  }

  onEnter(){
    let me = this;

    if(me._highlightedItem && me.items && me.items.length > 0){
      let i;
      if(me.key) i = me.items.findIndex(_item =>  _item[me.key] == me._highlightedItem[me.key] );
      else i = me.items.indexOf(me._highlightedItem)
      me.onSelect(me.items[i])
      if(me.clearOnBlur) me._value = '';
    }
  }

  onSelect(item){
    let me = this;
    me.value = item;
    if(me.key && me.value) me._value = me.value[me.key];
    else if (me.value) me._value = me.value;
    me.selected.emit(me.value)
    me.cd.markForCheck();
  }

  onHighLight(item){
    let me = this;
    me._highlightedItem = item;
    me.cd.markForCheck();
  }

  clear(){
    this._value = '';
    this.value = undefined;
    this.cd.markForCheck();
  }
}

