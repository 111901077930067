import { ElementRef, HostListener, Directive, OnInit, OnChanges } from '@angular/core';

@Directive({
  selector: 'textarea[auto-size]'
})
export class AutoSize {

  constructor(public element: ElementRef){
	  this.element.nativeElement.style.overflow = 'hidden';
  }

  ngAfterViewChecked(){
    this.adjust();
  }

  adjust(){
	  this.element.nativeElement.style.height = 'auto';
    this.element.nativeElement.style.height = this.element.nativeElement.scrollHeight + "px";
  }
}
