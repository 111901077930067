import { BarcodeScannerService } from './../services/barcode-scanner.service';
import {Component,Input,Output,EventEmitter, OnChanges, AfterContentChecked, ViewChild, ElementRef} from '@angular/core';

@Component({
  selector: 'app-md-select',
  templateUrl: './md-select.component.html',
  styleUrls: ['./md-select.component.scss']
})
export class MdSelectComponent implements OnChanges, AfterContentChecked {
  @ViewChild('selector') selector:ElementRef;
  @Input('barcode-type') barcodeType:string;
  @Input('value') value:any;
  @Input('placeholder') placeholder:string;
  @Input('float') float:boolean;
  @Input() disabled: boolean = false;
  @Input('required') required:boolean;
  @Output('input') input:EventEmitter<any> = new EventEmitter();
  @Output('changed') changed:EventEmitter<any> = new EventEmitter();
  @Output('focus') focusEvent:EventEmitter<any> = new EventEmitter();
  @Output('blur') blur:EventEmitter<any> = new EventEmitter();
  _isFocused = false;
  _barcodeEvent:any;

  constructor(private BarcodeScannerService:BarcodeScannerService){

  }
  
  onInput(event){
      this.value = event.target.value;
      this.input.emit(event);
  }
  
  onChanged(event) {
      this.value = event.target.value;
      this.changed.emit(event); 
  }
  
  onFocus(event) {
      let me = this;
      me._isFocused = true;
      me.focusEvent.emit(event);
      if(me.barcodeType && me.barcodeType.length > 0) {
      me._barcodeEvent = me.BarcodeScannerService.OverRidingBarcodeEvent(me.barcodeType).subscribe(_barcode=>{
        me.onInput({target:{value:_barcode.text}})
      })
    }
  }
  
  onBlur(event) {
      let me = this;
      me.value = event.target.value;
      me._isFocused = false;
      if(me._barcodeEvent && me._barcodeEvent.unsubscribe) me._barcodeEvent.unsubscribe()
      me.blur.emit(event); 
  }

  focus(){
      this.selector.nativeElement.focus();
  }

  ngOnChanges(data){

  }

  ngAfterContentChecked(){
      this.selector.nativeElement.value = this.value;
  }
}

