import { ApplicationRef, Injectable, Inject  } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
//import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class CheckForUpdateService {

  constructor(appRef: ApplicationRef, updates: SwUpdate) {
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    //appRef.isStable.pipe(first(isStable => isStable === true)).take(1).toPromise().then(()=>{
    //if ('serviceWorker' in navigator && environment.production) console.log('CheckForUpdateService')
    setTimeout(()=>{
      //console.log('first check for updates')
      //console.log({isEnabled:updates.isEnabled})
      if ('serviceWorker' in navigator && environment.production) updates.checkForUpdate() 
      setInterval(()=>{
        if ('serviceWorker' in navigator && environment.production) updates.checkForUpdate()
       },1000*60)
    },1000*10)
      
     //});
    
     if ('serviceWorker' in navigator && environment.production) updates.available.subscribe(event => {
      console.log('update available')
      console.log('current version is', event.current);
      console.log('available version is', event.available);
    });
    if ('serviceWorker' in navigator && environment.production) updates.activated.subscribe(event => {
      console.log('update activated')
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    });

    // const everySixHours$ = interval(6 * 60 * 60 * 1000);
    // const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    // everySixHoursOnceAppIsStable$.subscribe(() => updates.checkForUpdate());
  }
}