import { AuditsService } from './../../../../services/audits.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges,ViewEncapsulation,ChangeDetectionStrategy,ChangeDetectorRef } from '@angular/core';
import {SamplesService, Isample} from '../../../../services/samples.service';

@Component({
  selector: 'app-sample-submission-row',
  templateUrl: './sample-submission-row.component.html',
  styleUrls: ['./sample-submission-row.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class SampleSubmissionRowComponent implements OnInit, OnChanges {
  @Input('sample') sample:Isample = {};
  @Input('customer-id') customerId:string;
  @Input('submistion-id') submistionId:string;
  @Input('assays') assays:any[] = [];
  @Input('disabled') disabled:boolean = true;
  @Input('form-dirty') formDirty:boolean;
  @Input('custom-fields') customFields:any[] = [];
  @Output('changed') changeEvent:EventEmitter<any> = new EventEmitter;
  @Input('is-bia') isBia:boolean;
  defaultAssays = [];
  selctedAssaylist = [];
  selctedDefaultAssaylist = [];
  sampleCustomeFields = [];
  sampleDescriptionSuggestions = [];
  sampleNameSuggestions = [];
  tanninSensitive:boolean = false;
  enzymeSensitive:boolean = false;
  get valid (){
    if(this.sample) return this.sample.valid;
    else false;
  }
  constructor(private SamplesService:SamplesService, private cd:ChangeDetectorRef, private AuditsService:AuditsService) { }

  ngOnInit() {

  }

  ngOnChanges(data){
    let me = this;
    if(data.assays || data.sample){
      let da = {};
      me.assays.filter(_assay =>{
        return _assay.isDefault;
      }).forEach(_assay =>{
        da[_assay.testingFor] = _assay;
      })

      me.selctedDefaultAssaylist = [];
      if(me.sample && me.sample.defaultAssaysSelected){
        for(let key in me.sample.defaultAssaysSelected){
          if(!da[key]) da[key] = me.sample.defaultAssaysSelected[key];
          me.selctedDefaultAssaylist.push(me.sample.defaultAssaysSelected[key]);
          if(me.sample.defaultAssaysSelected[key].tannins) me.tanninSensitive = true;
          if(me.sample.defaultAssaysSelected[key].enzymes) me.enzymeSensitive = true;
        }
      }

      me.defaultAssays = [];
      for(let key in da){
        me.defaultAssays.push(da[key]);
      }

      me.selctedAssaylist = [];
      if(me.sample && me.sample.assaysSelected){
        for(let key in me.sample.assaysSelected){
          let _assay = me.assays.find(__assay => __assay._id == key);
          if(!_assay){
            me.assays.push(me.sample.assaysSelected[key])
          }
          me.selctedAssaylist.push(me.sample.assaysSelected[key])
          if(me.sample.assaysSelected[key].tannins) me.tanninSensitive = true;
          if(me.sample.assaysSelected[key].enzymes) me.enzymeSensitive = true;
        }
      }
    }

    if(data.sample || data.customFields){
      me.sampleCustomeFields = me.SamplesService.clone(me.customFields);
      if(me.sample){
        for(let key in me.sample.customFields){
          if(!me.customFields.find(_field => _field._id == key)){
            me.sampleCustomeFields.push(me.sample.customFields[key])
          }
        }
      }

    }
    me.validateSample();
  }

  getSampleDescriptionSuggestions(event){
    let me = this;
    if(me.customerId){
      me.SamplesService.findSampleDescriptions(me.customerId,event.target.value).then((_descriptions:string[]) =>{
        if(me.sample.description == event.target.value)  me.sampleDescriptionSuggestions = _descriptions.filter(_description => _description != me.sample.description);
        else me.sampleDescriptionSuggestions = _descriptions;
        me.cd.markForCheck();
      })
    }
  }

  getSampleNameSuggestions(event){
    let me = this;
    if(me.customerId){
      me.SamplesService.findSampleNames(me.customerId,event.target.value).then(_names =>{
        if(me.sample.name == event.target.value)  me.sampleNameSuggestions = _names.filter(_name => _name != me.sample.name);
        else me.sampleNameSuggestions = _names;
        me.cd.markForCheck();
      })
    }
  }

  removeSample(){
    let me = this;
    me.disabled = true;
    me.SamplesService.set([me.sample._id,'active'],false);
    me.SamplesService.set([me.sample._id,'biaId'],me.sample._id);
    me.SamplesService.save(me.sample._id);
    me.AuditsService.log({submistionId:me.submistionId,sampleId:me.sample._id},'Remove Sample ' + me.sample.biaId,[{key:"active",value:false,oldValue:true}])
    me.cd.markForCheck();
  }

  changeValue(key,value){
    let me = this;
    me.SamplesService.set([me.sample._id,key],value)
    me.SamplesService.save(me.sample._id)
    me.AuditsService.log({submistionId:me.submistionId,sampleId:me.sample._id},'Update ' + key,[{key:key,value:value,oldValue:me.sample[key]}])
  }

  changeNumOfComposites(value){
    let me = this;
    me.SamplesService.set([me.sample._id,'numOfComposites'],value);

    if(value < 2 && (me.sample.name2 || me.sample.description2)) {
      me.SamplesService.set([me.sample._id,'name2'],undefined);
      me.SamplesService.set([me.sample._id,'description2'],undefined);
    }

    if(value < 3 && (me.sample.name3 || me.sample.description3)) {
      me.SamplesService.set([me.sample._id,'name3'],undefined);
      me.SamplesService.set([me.sample._id,'description3'],undefined);
    }

    if(value < 4 && (me.sample.name4 || me.sample.description4)) {
      me.SamplesService.set([me.sample._id,'name4'],undefined);
      me.SamplesService.set([me.sample._id,'description4'],undefined);
    }

    if(value < 5 && (me.sample.name5 || me.sample.description5)) {
      me.SamplesService.set([me.sample._id,'name5'],undefined);
      me.SamplesService.set([me.sample._id,'description5'],undefined);
    }

    me.SamplesService.save(me.sample._id);
    me.AuditsService.log({submistionId:me.submistionId,sampleId:me.sample._id},'Update Number Of Composites',[{key:'numOfComposites',value:value,oldValue:me.sample.numOfComposites}])
  }

  changeBiaId(value){
    let me = this;
    me.SamplesService.checkBiaIdEx(value).then(data =>{
      if(!data._id || data._id == me.sample._id){
        me.SamplesService.set([me.sample._id,'biaId'],value)
        me.SamplesService.save(me.sample._id)
        me.AuditsService.log({submistionId:me.submistionId,sampleId:me.sample._id},'Set BiaId for Sample',[{key:'biaId',value:value,oldValue:me.sample.biaId}])
      }
      else{
        let biaId = me.sample.biaId;
        setTimeout(()=>{
          me.SamplesService.set([me.sample._id,'biaId'],biaId)
        },300)
        me.SamplesService.set([me.sample._id,'biaId'],me.sample._id)
      }
    })
  }

  changeCustomeFieldValue(field,value){
    let me = this;
    if(!me.sample.customFields){
      let _val = {};
      _val[field._id] = me.SamplesService.clone(field);
      _val[field._id].value = ((field.type == 'number' || field.type == 'percent') ? parseFloat(value) : value || null);
      me.SamplesService.set([me.sample._id,'customFields'],_val)
    }
    else {
      let _field = me.SamplesService.clone(field);
      _field.value = ((_field.type == 'number' || _field.type == 'percent') ? parseFloat(value) : value || null);
      me.SamplesService.set([me.sample._id,'customFields',field._id],_field);
    }

    me.SamplesService.save(me.sample._id)
    me.AuditsService.log({submistionId:me.submistionId,sampleId:me.sample._id},'Update Custom Field ' + field.name ,[{key:'customFields',value:value,oldValue:me.sample.customFields}])
  }

  changeDefaulAssaysSelected(assay, value){
    let me = this;

    if(!me.sample.defaultAssaysSelected){
      let _val = {};
      _val[assay.testingFor] = value?assay:undefined;
      me.SamplesService.set([me.sample._id,'defaultAssaysSelected'],_val)
    }
    else me.SamplesService.set([me.sample._id,'defaultAssaysSelected',assay.testingFor],value?assay:undefined)
    me.SamplesService.save(me.sample._id);
    me.AuditsService.log({submistionId:me.submistionId,sampleId:me.sample._id},'Update Assays Selected' ,[{key:'defaultAssaysSelected',value:value,oldValue:me.sample.defaultAssaysSelected}])
    setTimeout(()=>{
      me.tanninEnzymeCheck();
    },300)
  }

  changeAssaysSelected(assay, value){
    let me = this;

    if(!me.sample.assaysSelected){
      let _val = {};
      _val[assay._id] = value?assay:undefined;
      me.SamplesService.set([me.sample._id,'assaysSelected'],_val)
    }
    else me.SamplesService.set([me.sample._id,'assaysSelected',assay._id],value?assay:undefined)
    me.SamplesService.save(me.sample._id);
    me.AuditsService.log({submistionId:me.submistionId,sampleId:me.sample._id},'Update Assays Selected' ,[{key:'assaysSelected',value:value,oldValue:me.sample.assaysSelected}])
    setTimeout(()=>{
      me.tanninEnzymeCheck();
    },300)
  }

  tanninEnzymeCheck(){
    let me = this;
    let _tannin = false, _enzyme = false;
    for (let i in me.sample.defaultAssaysSelected) {
      if(me.sample.defaultAssaysSelected[i].tannins) _tannin = true;
      if(me.sample.defaultAssaysSelected[i].enzymes) _enzyme = true;
    }

    for (let i in me.sample.assaysSelected) {
      if(me.sample.assaysSelected[i].tannins) _tannin = true;
      if(me.sample.assaysSelected[i].enzymes) _enzyme = true;
    }

    me.tanninSensitive = _tannin;
    me.enzymeSensitive = _enzyme;
    me.cd.markForCheck();
  }


  clone(obj){
    return JSON.parse(JSON.stringify(obj));
  }

  trackDefaultAssaysBy(defaultAssay){
    return defaultAssay.testingFor;
  }

  trackFullAssaylistBy(_assay){
    return _assay._id;
  }

  trackCustomFieldBy(_field){
    return _field._id;
  }

  validateSample(){
    let me = this, _valid = false;
    if(
      //me.sample.spikeNewSamples
    //&& me.sample.name && me.sample.name.length > 0 &&
     me.sample.description && me.sample.description.length > 0
    && (me.sample.numOfComposites < 2 || ( me.sample.description2 && me.sample.description2.length > 0))
    && (me.sample.numOfComposites < 3 || (me.sample.description3 && me.sample.description3.length > 0))
    && (me.sample.numOfComposites < 4 || (me.sample.description4 && me.sample.description4.length > 0))
    && (me.sample.numOfComposites < 5 || (me.sample.description5 && me.sample.description5.length > 0))
    && ((me.sample.defaultAssaysSelected && Object.keys(me.sample.defaultAssaysSelected).length > 0)
    || (me.sample.assaysSelected && Object.keys(me.sample.assaysSelected).length > 0))
    //&& (!me.tanninSensitive || me.sample.hasTannins) && (!me.enzymeSensitive || me.sample.hasEnzymes)
    )  {
      let customeFieldsValid = true;
      me.customFields.forEach(_field =>{
        if(_field.required){
          if(!me.sample.customFields || !me.sample.customFields[_field._id] || !me.sample.customFields[_field._id].value || me.sample.customFields[_field._id].value.length == 0) customeFieldsValid = false;
        }
      })
      _valid = customeFieldsValid;
    }

    if(me.sample.valid != _valid){
      me.SamplesService.set([me.sample._id,'valid'],_valid);
      me.SamplesService.save(me.sample._id)
    }
  }
}

