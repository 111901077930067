import { fourPFitService } from './../../services/4p-fit.service';

import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges } from '@angular/core';
import * as d3 from "d3";

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnChanges {
  @ViewChild('svg') svg:ElementRef;
  _max = 40;
  _maxY = 4
  @Input() set params(_value){
    this._curve = [];
    this._params = _value;

    if(this.standards && this.standards.length > 0){
      let sx = this.standards.map(s=>parseFloat(s.expected))
      this._max = Math.ceil(Math.max(...sx))

      let sy = this.standards.map(s=>parseFloat(s.OD))
      this._maxY = Math.ceil(Math.max(...sy))
    


    
      if(_value && !!_value.A && !!_value.B){
        console.log('_value')
        // norem
        if(sy[0] < this._maxY) for(let i =0;i<this._maxY;i+=.01){
          //console.log('norm')
          let x = this.fourPFitService.MyRawEquation([_value.A,_value.B,_value.C,_value.D],i)
          this._curve.push([x,i]);
          if(x > this._max) break;
        }
        //sandwitch
        else for(let i =this._maxY;i>0;i-=.01){
          //console.log('sandwitch')
          let x = this.fourPFitService.MyRawEquation([_value.A,_value.B,_value.C,_value.D],i)
          this._curve.push([x,i]);
          if(x < 0) break;
        }
      } 
    }

    

  };
  @Input() standards = [];
  @Input() samples = [];
  _svg:d3.Selection<any, any, null, undefined>
  _curve = []
  _params:any = {};

  constructor(private fourPFitService:fourPFitService) {

  }

  ngOnInit() {
    let sx = this.standards.map(s=>parseFloat(s.expected))
    let sy = this.standards.map(s=>parseFloat(s.OD))

    if(sy[0] < this._maxY){
      //console.log('norm')
      for(let i =0;i<this._max;i+=.01){
        this._curve.push([i,0]);
      }
    } 
    else {
      console.log('sandwitch')
      for(let i =this._maxY;i>0;i-=.01){
        this._curve.push([i,0]);
      }
    } 


    this._svg = d3.select(this.svg.nativeElement)
    var margin = {top: 30, right: 20, bottom: 30, left: 80},
    width = 600 - margin.left - margin.right,
    height = 270 - margin.top - margin.bottom;


    let x = d3.scaleLinear().range([0,width])
    let y = d3.scaleLinear().range([height,0])

    let stds = (this.standards || []).map(cell=>{
      return [cell.expected,cell.OD]
    })// [[1,1],[2,2],[3,3]]
    let samps = (this.samples || []).map(cell=>{
      return [cell.expected,cell.OD]
    })
    let dataB = this._curve //[[0,0],[.5,0],[.7,.5],[1,1],[1.2,1.7],[2,2],[2.1,2.6],[2.6,2.8],[3,3]]

// Define the axes

    // var xAxis = d3.svg.axis().scale(x)
    // .orient("bottom").ticks(5);
    let xAxis = d3.axisBottom(x)

    // var yAxis = d3.svg.axis().scale(y)
    // .orient("left").ticks(5);
    let yAxis = d3.axisLeft(y)

// Define the line
// var valueline = d3.svg.line()
//     .x(function(d) { return x(d.date); })
//     .y(function(d) { return y(d.close); });

    let valueline = d3.line().x(d=>x(d[0])).y(d=>y(d[1]))

// Adds the svg canvas

   //this._svg = d3.select(this.svg.nativeElement)
    // .append("svg")
    //     .attr("width", width + margin.left + margin.right)
    //     .attr("height", height + margin.top + margin.bottom)
    this._svg.append("g")
        .attr("transform",
              "translate(" + margin.left + "," + margin.top + ")");

// Get the data

    // data.forEach(function(d) {
    //     d.date = parseDate(d.date);
    //     d.close = +d.close;
    // });

    // Scale the range of the data
    x.domain([0,this._max]);
    y.domain([0, 3]);

    // Add the valueline path.
    this._svg.append("path").datum(dataB)
        .attr("class","line")
        .attr("style", "stroke:steelblue;stroke-width: 1.5;fill: none;")
        .attr("d", valueline);


    // Add the scatterplot
    this._svg.append("g").attr("class","stds")
        .data(stds)
      .enter().append("circle")
        .attr("r", 3.5)
        .attr("cx", function(d) { return x(d[0]); })
        .attr("cy", function(d) { return y(d[1]); }).attr("style","fill:cornflowerblue;");

    this._svg.append("g").attr("class","samps")
        .data(samps)
      .enter().append("circle")
        .attr("r", 2.5)
        .attr("cx", function(d) { return x(d[0]); })
        .attr("cy", function(d) { return y(d[1]); }).attr("style","fill:red;");

    // Add the X Axis
    this._svg.append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + height + ")")
        .style("font-size", "8px")
        .style("font-weight", "700")
        .call(xAxis);

    // Add the Y Axis
    this._svg.append("g")
        .attr("class", "y axis")
        .style("font-size", "8px")
        .style("font-weight", "700")
        .call(yAxis);

    this._svg.selectAll(".xaxis text")  // select all the text elements for the xaxis
          .attr("transform", function(d) {
             return "translate(" + height*-2 + "," + height + ")rotate(-45)";
         });

    // now add titles to the axes
    this._svg.append("text")
        .attr("text-anchor", "middle")  // this makes it easy to centre the text as the transform is applied to the anchor
        .attr("transform", "translate("+ (-(2.6*margin.left)/8) +","+(height/2)+")rotate(-90)")  // text is drawn off the screen top left, move down and out and rotate
        .style("font-size", "10px")
        .style("font-weight", "700")
        .text("O.D.");

    this._svg.append("text")
        .attr("text-anchor", "middle")  // this makes it easy to centre the text as the transform is applied to the anchor
        .attr("transform", "translate("+ (width/2) +","+((height + margin.top + margin.bottom)-(margin.top + margin.bottom)/3)+")")  // centre below axis
        .style("font-size", "10px")
        .style("font-weight", "700")
        .text("Concentration");

  }
  ngOnChanges(){
    if(this._params && !!this._params.A){

      this._curve = [];
      if(this.standards && this.standards.length > 0){
        let sx = this.standards.map(s=>parseFloat(s.expected))
        this._max = Math.ceil(Math.max(...sx))

        let sMin = Math.min(...sx)
  
        let sy = this.standards.map(s=>parseFloat(s.OD))
        let mY = Math.max(...sy)
        this._maxY = Math.ceil(mY)
        
      
  
  
      
        if(this._params && !!this._params.A && !!this._params.B){
          if(sy[0] < mY){
            console.log('norm')
            for(let i =0;i<this._maxY;i+=.01){
              let x = this.fourPFitService.MyRawEquation([this._params.A,this._params.B,this._params.C,this._params.D],i)
              this._curve.push([x,i]);
              if(x > this._max) break;
            }
          }
          else {
            console.log('sandwitch')
            for(let i =this._maxY;i>0;i-=.01){
              let x = this.fourPFitService.MyRawEquation([this._params.A,this._params.B,this._params.C,this._params.D],i)
              this._curve.push([x,i]);
              if(x > this._max) break;
              //if(x < sMin) break;
            }
          }
          
        } 
      }



      var margin = {top: 30, right: 20, bottom: 30, left: 70},
      width = 600 - margin.left - margin.right,
      height = 270 - margin.top - margin.bottom;
      let stds = (this.standards || []).map(cell=>{
        return [cell.expected,cell.OD]
      })
      let samps = (this.samples || []).map(cell=>{
        return [cell.expected,cell.OD]
      })
      let x = d3.scaleLinear().range([0,width])
      let y = d3.scaleLinear().range([height,0])
      let valueline = d3.line().x(d=>x(d[0])).y(d=>y(d[1]))
      x.domain([0,d3.max([d3.max(this._curve, function(d) { return d[0]; }),d3.max(samps,d=>d[0])])]);
      y.domain([0,d3.max([d3.max(this._curve,d=>d[1]),d3.max(samps,d=>d[1])])+.5]);
      let xAxis:any = d3.axisBottom(x)
      let yAxis:any = d3.axisLeft(y)
      let line = this._svg.select(".line");  // change the line
      line.transition().duration(1750)
              .attr("d", valueline(this._curve));



      let stdDots = this._svg.select(".stds").selectAll("circle").data(stds)
      stdDots.transition().duration(1750).attr("r", 3.5)
        .attr("cx", function(d) { return x(d[0]); })
        .attr("cy", function(d) { return y(d[1]); });
      stdDots.enter()
                .append("circle")
        .attr("r", 3.5)
        .attr("cx", function(d) { return x(d[0]); })
        .attr("cy", function(d) { return y(d[1]); }).attr("style","fill:cornflowerblue;");
      let sampDots = this._svg.select(".samps").selectAll("circle").data(samps)
      sampDots.transition().duration(1750).attr("r", 2.5)
        .attr("cx", function(d) { return x(d[0]); })
        .attr("cy", function(d) { return y(d[1]); });
      sampDots.enter()
                .append("circle")
        .attr("r", 2.5)
        .attr("cx", function(d) { return x(d[0]); })
        .attr("cy", function(d) { return y(d[1]); }).attr("style","fill:red;");


      this._svg.selectAll(".x.axis").transition() // change the x axis
            .duration(1750)
            .call(xAxis);
      this._svg.selectAll(".y.axis").transition() // change the y axis
            .duration(1750)
            .call(yAxis);
    }

  }


}
