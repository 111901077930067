<div fxLayout="column" fxLayoutAlign="start center" style="height:100vh; overflow:auto;">
  <img src="/assets/logo_500.jpg" class="logo" style="margin-top:150px; margin-bottom:40px;">
  <div class="signin-welcome">Welcome to the Bia Portal</div>
  <mat-card class="signin-card">
    <mat-card-header>
        <mat-card-title>
            <span class="title">Sign In</span>
          </mat-card-title>
      </mat-card-header>
    <mat-card-content fxLayout="column">
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="center center" class="signin-input-group" [style.border]="emailVCode == 'code'?'none':'1px solid #ccc'" [style.margin-bottom]="emailVCode == 'code'?'0':'16px'">
          <span [hidden]="emailVCode == 'code'"><i class="material-icons signin-icon">mail_outline</i></span>
          <input #emailInput [disabled]="sendingCodeInprogress" [hidden]="emailVCode == 'code'" class="signin-input" placeholder="Email Address" [value]="email" (keyup)="emailChange(emailInput.value)" />
          <span *ngIf="showNoEmailFoundMsg">bad email</span>
          <span *ngIf="sendingCodeInprogress" >sending Code</span>
        </div>
      </div>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="center center" class="signin-input-group" [style.border]="emailVCode == 'email'?'none':'1px solid #ccc'" [style.margin-bottom]="emailVCode == 'email'?'0':'10px'">
          <span [hidden]="emailVCode == 'email'"><i class="material-icons signin-icon">vpn_key</i></span>
          <input #codeInput [disabled]="checkingCodeInProgress" [hidden]="emailVCode == 'email'" class="signin-input" placeholder="Authentication Key" [value]="key" (keyup)="keyChange(codeInput.value)" />
          <span *ngIf="showBadCodeMsg">bad code</span>
          <span *ngIf="checkingCodeInProgress" >Checking Code</span>
        </div>
      </div>
      <div fxLayoutAlign="center center">
          <mat-checkbox [hidden]="emailVCode == 'email'" class="remember-me" aria-label="Remember Me" #keepMeLoggedInCheckbox>Remember Me</mat-checkbox>
      </div>
      <button [disabled]="sendingCodeInprogress" *ngIf="emailVCode == 'email'" mat-raised-button class="signin-button" (click)="sendLogInCode(); cd.markForCheck();" [disabled]="email.trim().length == 0">SEND AUTHENTICATION KEY</button>
      <button [disabled]="checkingCodeInProgress" *ngIf="emailVCode == 'code'" mat-raised-button class="signin-button" (click)="login(keepMeLoggedInCheckbox.checked)">SIGN IN</button>
      <div *ngIf="emailVCode == 'code'" style="margin: 16px 4px;
      color: white;
      font-size: 16px;
      text-align: center;" >
        Please check your email for a one-time use access code
      </div>
    </mat-card-content>
  </mat-card>
</div>
