import { AuditsService } from './../../../../services/audits.service';
import { OnInit, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';
import { SamplesService } from '../../../../services/samples.service';
var SampleSubmissionRowComponent = /** @class */ (function () {
    function SampleSubmissionRowComponent(SamplesService, cd, AuditsService) {
        this.SamplesService = SamplesService;
        this.cd = cd;
        this.AuditsService = AuditsService;
        this.sample = {};
        this.assays = [];
        this.disabled = true;
        this.customFields = [];
        this.changeEvent = new EventEmitter;
        this.defaultAssays = [];
        this.selctedAssaylist = [];
        this.selctedDefaultAssaylist = [];
        this.sampleCustomeFields = [];
        this.sampleDescriptionSuggestions = [];
        this.sampleNameSuggestions = [];
        this.tanninSensitive = false;
        this.enzymeSensitive = false;
    }
    Object.defineProperty(SampleSubmissionRowComponent.prototype, "valid", {
        get: function () {
            if (this.sample)
                return this.sample.valid;
            else
                false;
        },
        enumerable: true,
        configurable: true
    });
    SampleSubmissionRowComponent.prototype.ngOnInit = function () {
    };
    SampleSubmissionRowComponent.prototype.ngOnChanges = function (data) {
        var me = this;
        if (data.assays || data.sample) {
            var da_1 = {};
            me.assays.filter(function (_assay) {
                return _assay.isDefault;
            }).forEach(function (_assay) {
                da_1[_assay.testingFor] = _assay;
            });
            me.selctedDefaultAssaylist = [];
            if (me.sample && me.sample.defaultAssaysSelected) {
                for (var key in me.sample.defaultAssaysSelected) {
                    if (!da_1[key])
                        da_1[key] = me.sample.defaultAssaysSelected[key];
                    me.selctedDefaultAssaylist.push(me.sample.defaultAssaysSelected[key]);
                    if (me.sample.defaultAssaysSelected[key].tannins)
                        me.tanninSensitive = true;
                    if (me.sample.defaultAssaysSelected[key].enzymes)
                        me.enzymeSensitive = true;
                }
            }
            me.defaultAssays = [];
            for (var key in da_1) {
                me.defaultAssays.push(da_1[key]);
            }
            me.selctedAssaylist = [];
            if (me.sample && me.sample.assaysSelected) {
                var _loop_1 = function (key) {
                    var _assay = me.assays.find(function (__assay) { return __assay._id == key; });
                    if (!_assay) {
                        me.assays.push(me.sample.assaysSelected[key]);
                    }
                    me.selctedAssaylist.push(me.sample.assaysSelected[key]);
                    if (me.sample.assaysSelected[key].tannins)
                        me.tanninSensitive = true;
                    if (me.sample.assaysSelected[key].enzymes)
                        me.enzymeSensitive = true;
                };
                for (var key in me.sample.assaysSelected) {
                    _loop_1(key);
                }
            }
        }
        if (data.sample || data.customFields) {
            me.sampleCustomeFields = me.SamplesService.clone(me.customFields);
            if (me.sample) {
                var _loop_2 = function (key) {
                    if (!me.customFields.find(function (_field) { return _field._id == key; })) {
                        me.sampleCustomeFields.push(me.sample.customFields[key]);
                    }
                };
                for (var key in me.sample.customFields) {
                    _loop_2(key);
                }
            }
        }
        me.validateSample();
    };
    SampleSubmissionRowComponent.prototype.getSampleDescriptionSuggestions = function (event) {
        var me = this;
        if (me.customerId) {
            me.SamplesService.findSampleDescriptions(me.customerId, event.target.value).then(function (_descriptions) {
                if (me.sample.description == event.target.value)
                    me.sampleDescriptionSuggestions = _descriptions.filter(function (_description) { return _description != me.sample.description; });
                else
                    me.sampleDescriptionSuggestions = _descriptions;
                me.cd.markForCheck();
            });
        }
    };
    SampleSubmissionRowComponent.prototype.getSampleNameSuggestions = function (event) {
        var me = this;
        if (me.customerId) {
            me.SamplesService.findSampleNames(me.customerId, event.target.value).then(function (_names) {
                if (me.sample.name == event.target.value)
                    me.sampleNameSuggestions = _names.filter(function (_name) { return _name != me.sample.name; });
                else
                    me.sampleNameSuggestions = _names;
                me.cd.markForCheck();
            });
        }
    };
    SampleSubmissionRowComponent.prototype.removeSample = function () {
        var me = this;
        me.disabled = true;
        me.SamplesService.set([me.sample._id, 'active'], false);
        me.SamplesService.set([me.sample._id, 'biaId'], me.sample._id);
        me.SamplesService.save(me.sample._id);
        me.AuditsService.log({ submistionId: me.submistionId, sampleId: me.sample._id }, 'Remove Sample ' + me.sample.biaId, [{ key: "active", value: false, oldValue: true }]);
        me.cd.markForCheck();
    };
    SampleSubmissionRowComponent.prototype.changeValue = function (key, value) {
        var me = this;
        me.SamplesService.set([me.sample._id, key], value);
        me.SamplesService.save(me.sample._id);
        me.AuditsService.log({ submistionId: me.submistionId, sampleId: me.sample._id }, 'Update ' + key, [{ key: key, value: value, oldValue: me.sample[key] }]);
    };
    SampleSubmissionRowComponent.prototype.changeNumOfComposites = function (value) {
        var me = this;
        me.SamplesService.set([me.sample._id, 'numOfComposites'], value);
        if (value < 2 && (me.sample.name2 || me.sample.description2)) {
            me.SamplesService.set([me.sample._id, 'name2'], undefined);
            me.SamplesService.set([me.sample._id, 'description2'], undefined);
        }
        if (value < 3 && (me.sample.name3 || me.sample.description3)) {
            me.SamplesService.set([me.sample._id, 'name3'], undefined);
            me.SamplesService.set([me.sample._id, 'description3'], undefined);
        }
        if (value < 4 && (me.sample.name4 || me.sample.description4)) {
            me.SamplesService.set([me.sample._id, 'name4'], undefined);
            me.SamplesService.set([me.sample._id, 'description4'], undefined);
        }
        if (value < 5 && (me.sample.name5 || me.sample.description5)) {
            me.SamplesService.set([me.sample._id, 'name5'], undefined);
            me.SamplesService.set([me.sample._id, 'description5'], undefined);
        }
        me.SamplesService.save(me.sample._id);
        me.AuditsService.log({ submistionId: me.submistionId, sampleId: me.sample._id }, 'Update Number Of Composites', [{ key: 'numOfComposites', value: value, oldValue: me.sample.numOfComposites }]);
    };
    SampleSubmissionRowComponent.prototype.changeBiaId = function (value) {
        var me = this;
        me.SamplesService.checkBiaIdEx(value).then(function (data) {
            if (!data._id || data._id == me.sample._id) {
                me.SamplesService.set([me.sample._id, 'biaId'], value);
                me.SamplesService.save(me.sample._id);
                me.AuditsService.log({ submistionId: me.submistionId, sampleId: me.sample._id }, 'Set BiaId for Sample', [{ key: 'biaId', value: value, oldValue: me.sample.biaId }]);
            }
            else {
                var biaId_1 = me.sample.biaId;
                setTimeout(function () {
                    me.SamplesService.set([me.sample._id, 'biaId'], biaId_1);
                }, 300);
                me.SamplesService.set([me.sample._id, 'biaId'], me.sample._id);
            }
        });
    };
    SampleSubmissionRowComponent.prototype.changeCustomeFieldValue = function (field, value) {
        var me = this;
        if (!me.sample.customFields) {
            var _val = {};
            _val[field._id] = me.SamplesService.clone(field);
            _val[field._id].value = ((field.type == 'number' || field.type == 'percent') ? parseFloat(value) : value || null);
            me.SamplesService.set([me.sample._id, 'customFields'], _val);
        }
        else {
            var _field = me.SamplesService.clone(field);
            _field.value = ((_field.type == 'number' || _field.type == 'percent') ? parseFloat(value) : value || null);
            me.SamplesService.set([me.sample._id, 'customFields', field._id], _field);
        }
        me.SamplesService.save(me.sample._id);
        me.AuditsService.log({ submistionId: me.submistionId, sampleId: me.sample._id }, 'Update Custom Field ' + field.name, [{ key: 'customFields', value: value, oldValue: me.sample.customFields }]);
    };
    SampleSubmissionRowComponent.prototype.changeDefaulAssaysSelected = function (assay, value) {
        var me = this;
        if (!me.sample.defaultAssaysSelected) {
            var _val = {};
            _val[assay.testingFor] = value ? assay : undefined;
            me.SamplesService.set([me.sample._id, 'defaultAssaysSelected'], _val);
        }
        else
            me.SamplesService.set([me.sample._id, 'defaultAssaysSelected', assay.testingFor], value ? assay : undefined);
        me.SamplesService.save(me.sample._id);
        me.AuditsService.log({ submistionId: me.submistionId, sampleId: me.sample._id }, 'Update Assays Selected', [{ key: 'defaultAssaysSelected', value: value, oldValue: me.sample.defaultAssaysSelected }]);
        setTimeout(function () {
            me.tanninEnzymeCheck();
        }, 300);
    };
    SampleSubmissionRowComponent.prototype.changeAssaysSelected = function (assay, value) {
        var me = this;
        if (!me.sample.assaysSelected) {
            var _val = {};
            _val[assay._id] = value ? assay : undefined;
            me.SamplesService.set([me.sample._id, 'assaysSelected'], _val);
        }
        else
            me.SamplesService.set([me.sample._id, 'assaysSelected', assay._id], value ? assay : undefined);
        me.SamplesService.save(me.sample._id);
        me.AuditsService.log({ submistionId: me.submistionId, sampleId: me.sample._id }, 'Update Assays Selected', [{ key: 'assaysSelected', value: value, oldValue: me.sample.assaysSelected }]);
        setTimeout(function () {
            me.tanninEnzymeCheck();
        }, 300);
    };
    SampleSubmissionRowComponent.prototype.tanninEnzymeCheck = function () {
        var me = this;
        var _tannin = false, _enzyme = false;
        for (var i in me.sample.defaultAssaysSelected) {
            if (me.sample.defaultAssaysSelected[i].tannins)
                _tannin = true;
            if (me.sample.defaultAssaysSelected[i].enzymes)
                _enzyme = true;
        }
        for (var i in me.sample.assaysSelected) {
            if (me.sample.assaysSelected[i].tannins)
                _tannin = true;
            if (me.sample.assaysSelected[i].enzymes)
                _enzyme = true;
        }
        me.tanninSensitive = _tannin;
        me.enzymeSensitive = _enzyme;
        me.cd.markForCheck();
    };
    SampleSubmissionRowComponent.prototype.clone = function (obj) {
        return JSON.parse(JSON.stringify(obj));
    };
    SampleSubmissionRowComponent.prototype.trackDefaultAssaysBy = function (defaultAssay) {
        return defaultAssay.testingFor;
    };
    SampleSubmissionRowComponent.prototype.trackFullAssaylistBy = function (_assay) {
        return _assay._id;
    };
    SampleSubmissionRowComponent.prototype.trackCustomFieldBy = function (_field) {
        return _field._id;
    };
    SampleSubmissionRowComponent.prototype.validateSample = function () {
        var me = this, _valid = false;
        if (
        //me.sample.spikeNewSamples
        //&& me.sample.name && me.sample.name.length > 0 &&
        me.sample.description && me.sample.description.length > 0
            && (me.sample.numOfComposites < 2 || (me.sample.description2 && me.sample.description2.length > 0))
            && (me.sample.numOfComposites < 3 || (me.sample.description3 && me.sample.description3.length > 0))
            && (me.sample.numOfComposites < 4 || (me.sample.description4 && me.sample.description4.length > 0))
            && (me.sample.numOfComposites < 5 || (me.sample.description5 && me.sample.description5.length > 0))
            && ((me.sample.defaultAssaysSelected && Object.keys(me.sample.defaultAssaysSelected).length > 0)
                || (me.sample.assaysSelected && Object.keys(me.sample.assaysSelected).length > 0))
        //&& (!me.tanninSensitive || me.sample.hasTannins) && (!me.enzymeSensitive || me.sample.hasEnzymes)
        ) {
            var customeFieldsValid_1 = true;
            me.customFields.forEach(function (_field) {
                if (_field.required) {
                    if (!me.sample.customFields || !me.sample.customFields[_field._id] || !me.sample.customFields[_field._id].value || me.sample.customFields[_field._id].value.length == 0)
                        customeFieldsValid_1 = false;
                }
            });
            _valid = customeFieldsValid_1;
        }
        if (me.sample.valid != _valid) {
            me.SamplesService.set([me.sample._id, 'valid'], _valid);
            me.SamplesService.save(me.sample._id);
        }
    };
    return SampleSubmissionRowComponent;
}());
export { SampleSubmissionRowComponent };
